import React from 'react';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldRich from '@dex/bubl-dash/src/lib/components/Fields/FieldRich';
import { Row, Col, Space, Box } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import { get } from '@dex/bubl-helpers';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';

const FieldBlocks: React.FC<any> = (props: any) => {

    const { form, name } = props;

    let values = form.getValue(name) || [];

    const disabled = props.disabled || [];

    const defaultValues = props.defaultValues || {};

    if (!defaultValues.innerBlocks) defaultValues.innerBlocks = [];

    return (
        <>

            <FieldRepeater
                addButtonLabel={"Add Block"}
                collapseAll={true}
                primaryName={"type"}
                {...props}
                defaultValues={defaultValues}
            >

                {(values || []).map((row, n) => (

                    <React.Fragment key={form.key + "-" + n}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldSelect
                                    form={form}
                                    label={"Block Type"}
                                    name={`${name}[${n}].type`}
                                    required={true}
                                    defaultValue={row.type}
                                    options={[
                                        !disabled.includes("text") && { value: 'text', label: "Text" },
                                        !disabled.includes("image") && { value: 'image', label: "Image" },
                                        !disabled.includes("video") && { value: 'video', label: "Video" },
                                        !disabled.includes("space") && { value: 'space', label: "Space" },
                                        !disabled.includes("heading") && { value: 'heading', label: "Heading" },
                                        !disabled.includes("menuItem") && { value: 'menuItem', label: "Menu Item" },
                                        !disabled.includes("accordion") && { value: 'accordion', label: "Accordion" },
                                        !disabled.includes("section") && { value: 'section', label: "Section" },
                                    ].filter(Boolean)}
                                />

                            </Col>

                        </Row>

                        {row.type === "text" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldRich
                                        form={form}
                                        label={"Text"}
                                        name={`${name}[${n}].text`}
                                        required={true}
                                        defaultValue={row.text}
                                    />

                                </Col>

                            </Row>
                        }

                        {row.type === "image" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldUpload
                                        form={form}
                                        folder={'admin-media'}
                                        label={"Image"}
                                        name={`${name}[${n}].image`}
                                        required={true}
                                        defaultValue={row.image}
                                        limit={1}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <Row gutter={8} edge={true}>

                                        <Col col={{ xs: 24, md: 24 }}>

                                            <FieldText
                                                form={form}
                                                label={"Caption"}
                                                name={`${name}[${n}].caption`}
                                                required={false}
                                                defaultValue={row.caption}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 24 }}>

                                            <FieldText
                                                form={form}
                                                label={"Background Color"}
                                                name={`${name}[${n}].style.backgroundColor`}
                                                required={false}
                                                placeholder={"transparent"}
                                                defaultValue={get(row, 'style.backgroundColor')}
                                            />

                                        </Col>

                                    </Row>

                                </Col>

                            </Row>
                        }

                        {row.type === "video" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldUpload
                                        form={form}
                                        folder={'admin-media'}
                                        label={"Video"}
                                        name={`${name}[${n}].video`}
                                        required={true}
                                        defaultValue={row.video}
                                        limit={1}
                                        imageOnly={false}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldSwitch
                                        form={form}
                                        label={"Loop"}
                                        name={`${name}[${n}].loop`}
                                        defaultValue={row.loop}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldSwitch
                                        form={form}
                                        label={"Auto Play"}
                                        name={`${name}[${n}].shouldPlay`}
                                        defaultValue={row.shouldPlay}
                                    />

                                </Col>

                            </Row>
                        }

                        {row.type === "heading" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Heading"}
                                        name={`${name}[${n}].heading`}
                                        required={true}
                                        defaultValue={row.heading}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Sub Heading"}
                                        name={`${name}[${n}].subHeading`}
                                        required={false}
                                        defaultValue={row.subHeading}
                                    />

                                </Col>

                            </Row>
                        }

                        {row.type === "teamMember" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 12 }}>

                                    <FieldText
                                        form={form}
                                        label={"Name"}
                                        name={`${name}[${n}].name`}
                                        required={true}
                                        defaultValue={row.name}
                                    />

                                    <Space height={8} />

                                    <FieldText
                                        form={form}
                                        label={"Designation"}
                                        name={`${name}[${n}].designation`}
                                        required={false}
                                        defaultValue={row.designation}
                                    />

                                </Col>

                                <Col col={{ xs: 12 }}>

                                    <FieldUpload
                                        form={form}
                                        folder={'admin-media'}
                                        label={"Photo"}
                                        name={`${name}[${n}].photo`}
                                        required={false}
                                        defaultValue={row.photo}
                                        limit={1}
                                    />

                                </Col>

                            </Row>
                        }

                        {row.type === "staticComponent" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Component Name"}
                                        name={`${name}[${n}].name`}
                                        required={true}
                                        defaultValue={row.name}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Component Props"}
                                        name={`${name}[${n}].props`}
                                        required={false}
                                        defaultValue={row.props}
                                    />

                                </Col>

                            </Row>
                        }

                        {row.type === "space" &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldRadio
                                        form={form}
                                        label={"Height"}
                                        name={`${name}[${n}].height`}
                                        required={true}
                                        defaultValue={row.height}
                                        options={[
                                            { label: "XS (4px)", value: "4px" },
                                            { label: "S (8px)", value: "8px" },
                                            { label: "M (12px)", value: "12px" },
                                            { label: "L (16px)", value: "16px" },
                                            { label: "XL (20px)", value: "20px" },
                                            { label: "XXL (24px)", value: "24px" },
                                        ]}
                                    />

                                </Col>

                            </Row>
                        }

                        {(row.type === "accordion" || row.type === "section") &&
                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Heading"}
                                        name={`${name}[${n}].heading`}
                                        required={true}
                                        defaultValue={row.heading}
                                    />

                                </Col>

                                {row.type === "section" &&
                                    <Col col={{ xs: 24 }}>

                                        <FieldText
                                            form={form}
                                            label={"Sub Heading"}
                                            name={`${name}[${n}].subHeading`}
                                            required={false}
                                            defaultValue={row.subHeading}
                                        />

                                    </Col>
                                }

                                <Col col={{ xs: 24 }}>

                                    <FieldBlocks
                                        form={form}
                                        addButtonLabel={"Inner Content Block"}
                                        name={`${name}[${n}].innerBlocks`}
                                        required={false}
                                        defaultValue={row.innerBlocks}
                                        disabled={["accordion", "heading", "section"]}
                                    />

                                </Col>

                            </Row>
                        }

                        {row.type === "menuItem" &&

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Heading"}
                                        name={`${name}[${n}].heading`}
                                        required={true}
                                        defaultValue={row.heading}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Sub Heading"}
                                        name={`${name}[${n}].subHeading`}
                                        required={false}
                                        defaultValue={row.subHeading}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Link"}
                                        name={`${name}[${n}].link`}
                                        required={false}
                                        defaultValue={row.link}
                                    />

                                </Col>

                            </Row>
                        }

                    </React.Fragment>

                ))}

            </FieldRepeater>

        </>
    )

}

export default FieldBlocks;