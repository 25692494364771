import React from 'react';
import styles from "./PostPaidBadge.module.scss";
import { RiReplyFill } from "react-icons/ri";

const PostPaidBadge: React.FC<PostPaidBadgeProps> = (props) => {

    return (
        <RiReplyFill className={styles.icon} />
    )

}

interface PostPaidBadgeProps {
    [key: string]: any,
}

export default PostPaidBadge;