import React, { useEffect, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldAutocomplete, FieldCheckbox, FieldColor, FieldNumber, FieldRadio, FieldRelationship, FieldRich, FieldSelect, FieldUpload, Seperator } from '@dex/bubl-dash';
import StatusDot from '../../../elements/StatusDot/StatusDot';
import { USE_CREDITS, USE_PASS } from '../../../functions/mode';
import StylesApi from '../../Styles/StylesApi';

const GroupsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const style = StylesApi.getOne(":id");

    useEffect(() => {

        style.reset();

        if (!form.values.styleId) return;

        style.run({
            url: style.options.url?.replace(":id", form.values.styleId)
        })

        return () => {
            style.cancel();
        }

    }, [form.values.styleId]);

    return useMemo(() => (


        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >


            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 16 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Type"}
                                name='type'
                                required={true}
                                defaultValue={form.defaults.type}
                                options={[
                                    { label: "Class", value: "class" },
                                    { label: "Social / Practice", value: "social-practice" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldText
                                form={form}
                                label={"Title"}
                                name='shortTitle'
                                required={true}
                                defaultValue={form.defaults.shortTitle}
                                transform={'capitalize'}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRelationship
                                form={form}
                                label={"Style"}
                                name='styleId'
                                required={true}
                                model={'Styles'}
                                preload={true}
                                defaultValue={form.defaults.styleId}
                            />

                        </Col>

                        {USE_CREDITS &&
                            <>
                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Credits To Attend Class"}
                                        name='creditsAttend'
                                        required={true}
                                        defaultValue={form.defaults.creditsAttend}
                                        decimalScale={0}
                                        placeholder='#'
                                        suffix=" credits"
                                    />

                                </Col>

                                {style.data?.supportsRetake && form.values.type === "class" &&
                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldNumber
                                            form={form}
                                            label={"Credits To Retake Class"}
                                            name='creditsRetake'
                                            required={false}
                                            defaultValue={form.defaults.creditsRetake}
                                            decimalScale={0}
                                            placeholder='#'
                                            suffix=" credits"
                                        />

                                    </Col>
                                }
                            </>
                        }

                    </Row>

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Status"}
                                name='status'
                                required={true}
                                defaultValue={form.defaults.status}
                                options={[
                                    { label: "Active", value: "active" },
                                    { label: "Inactive", value: "inactive" },
                                ]}
                            />

                        </Col>

                    </Row>

                </Col>

            </Row>

            <Seperator
                top={"auto"}
                bottom={"auto"}
                heading={"Calendar Display"}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        label={"Label"}
                        name='calendarLabel'
                        defaultValue={form.defaults.calendarLabel}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldSelect
                        form={form}
                        label={"Color"}
                        name='calendarColor'
                        required={true}
                        defaultValue={form.defaults.calendarColor}
                        options={[
                            { value: "yellow", label: <><StatusDot color="yellow" /> Yellow</> },
                            { value: "gold", label: <><StatusDot color="gold" /> Gold</> },
                            { value: "peach", label: <><StatusDot color="peach" /> Peach</> },
                            { value: "orange", label: <><StatusDot color="orange" /> Orange</> },
                            { value: "salmon", label: <><StatusDot color="salmon" /> Salmon</> },
                            { value: "pink", label: <><StatusDot color="pink" /> Pink</> },
                            { value: "red", label: <><StatusDot color="red" /> Red</> },
                            { value: "maroon", label: <><StatusDot color="brown" /> Brown</> },
                            { value: "purple", label: <><StatusDot color="purple" /> Purple</> },
                            { value: "mauve", label: <><StatusDot color="mauve" /> Mauve</> },
                            { value: "blue", label: <><StatusDot color="blue" /> Blue</> },
                            { value: "teal", label: <><StatusDot color="teal" /> Teal</> },
                            { value: "green", label: <><StatusDot color="green" /> Green</> },
                            { value: "mint", label: <><StatusDot color="mint" /> Mint</> },
                            { value: "gray", label: <><StatusDot color="gray" /> Gray</> },
                            { value: "black", label: <><StatusDot color="black" /> Black</> },
                        ]}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldUpload
                        form={form}
                        folder={'admin-media'}
                        label={"Icon"}
                        name={'calendarIcon'}
                        required={false}
                        defaultValue={form.defaults.calendarIcon}
                        accept={"image/*"}
                        limit={1}

                    />


                </Col>

            </Row>

            <ClassDefaults
                form={form}
            />

            <Line />

            <ButtonRow>
                <Button
                    label={"Save"}
                    loading={process.loading}
                />
                <Button
                    label={"Reset"}
                    type={"faded"}
                    onClick={form.reset}
                />
            </ButtonRow>

        </Form>


    ), [props, style.hash]);

}

const ClassDefaults: React.FC<any> = (props: any) => {

    const { form } = props;

    return (


        <>

            <Seperator
                top={"auto"}
                bottom={"auto"}
                heading={"Class Defaults"}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 16 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldAutocomplete
                                form={form}
                                label={"Location"}
                                name='location'
                                defaultValue={form.defaults.location}
                                group={'location'}
                                preload={true}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldNumber
                                form={form}
                                label={"Class Duration"}
                                name='duration'
                                suffix={" mins"}
                                defaultValue={form.defaults.duration}
                                decimalScale={0}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldNumber
                                form={form}
                                label={"Limit Leads"}
                                name='limitLeads'
                                required={false}
                                defaultValue={form.defaults.limitLeads}
                                decimalScale={0}
                                placeholder='#'
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldNumber
                                form={form}
                                label={"Limit Follows"}
                                name='limitFollows'
                                required={false}
                                defaultValue={form.defaults.limitFollows}
                                decimalScale={0}
                                placeholder='#'
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldNumber
                                form={form}
                                label={"Limit Total Attendees"}
                                name='limitAttendees'
                                required={false}
                                defaultValue={form.defaults.limitAttendees}
                                decimalScale={0}
                                placeholder='#'
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldRich
                                form={form}
                                label={"Details"}
                                name='details'
                                required={false}
                                defaultValue={form.defaults.details}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    {USE_PASS &&
                        <Row gutter={8} edge>

                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Allowed Passes"}
                                    name='allowedPassesIds'
                                    model={'Products'}
                                    preload={true}
                                    defaultValue={form.defaults.allowedPassesIds}
                                    isMulti={true}
                                    where={{ type: "pass", status: "active" }}
                                />

                            </Col>

                            {form.values.type === "class" &&
                                <Col col={{ xs: 24, md: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Retake Passes"}
                                        name='retakePassesIds'
                                        required={false}
                                        model={'Products'}
                                        preload={true}
                                        defaultValue={form.defaults.retakePassesIds}
                                        isMulti={true}
                                        where={{ type: "pass", status: "active" }}
                                    />

                                </Col>
                            }

                        </Row>
                    }

                </Col>

            </Row>

        </>

    );

}


export default GroupsCreateEditForm;
