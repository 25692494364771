import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import GroupsApi from '../GroupsApi';
import GroupsCreateEditForm from './GroupsCreateEditForm';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';

const GroupsSingleEdit: React.FC<any> = (props: any) => {

    const app = useApp();

    const { data } = props;

    const form = useForm(data);

    const update = GroupsApi.update(data.id, "update", {
        onComplete: (data) => {

            app.alert('Class Group Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Class Group Details"}
                >

                    <GroupsCreateEditForm
                        mode={"edit"}
                        form={form}
                        data={data}
                        handleSubmit={handleSubmit}
                        process={update}
                    />

                </Panel>

            </Col>

        </Row>

    ), [data, form.hash, update]);

}

export default GroupsSingleEdit;
