import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldAutocomplete, FieldCheckbox, FieldColor, FieldNumber, FieldRadio, FieldRelationship, FieldRich, FieldSelect, FieldSwitch, FieldUpload, Seperator } from '@dex/bubl-dash';
import StatusDot from '../../../elements/StatusDot/StatusDot';
import { USE_CREDITS, USE_PASS } from '../../../functions/mode';

const StylesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Dance Style Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Title"}
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                    transform={'capitalize'}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Dance Format"}
                                    name='format'
                                    required={true}
                                    defaultValue={form.defaults.format}
                                    options={[
                                        { value: "solo", label: "Solo" },
                                        { value: "partner", label: "Partner (Lead/Follow)" }
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldSwitch
                                    form={form}
                                    label={"Supports Class Graduation"}
                                    name='supportsGraduation'
                                    info={"Enable Class Graduation Module."}
                                    defaultValue={form.defaults.supportsGraduation}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldSwitch
                                    form={form}
                                    label={"Supports Retake Classes"}
                                    name='supportsRetake'
                                    info={"Allow students to retake lower level classes at a different cost."}
                                    defaultValue={form.defaults.supportsRetake}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status"}
                                    name='status'
                                    required={false}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { label: "Active", value: "active" },
                                        { label: "Inactive", value: "inactive" },
                                    ]}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default StylesCreateEditForm;
