import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import ClassesCreateEditForm from "./Components/ClassesCreateEditForm";
import ClassesApi from "./ClassesApi";

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New Class Group",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: ClassesCreateEditForm,
                getCreate: ClassesApi.create,
                getClone: ClassesApi.getOne,
                defaults: { dates: [] },
                successCallback: (response, app, nav) => {
                    const route = { "type": "Navigation/NAVIGATE", routeName: settings.key, params: { batch: response.batch } };
                    app.navigate(nav, 'push', route);
                },
                idKey: settings.idKey,
            },
        },
    });

}