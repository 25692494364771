import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldRadio, FieldRelationship, FieldWrap, useForm } from '@dex/bubl-dash';
import ClassesApi from '../ClassesApi';


const ClassesAttendeesAddForm: React.FC<any> = (props: any) => {

    const { format, classId, styleId, handleRefresh } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const form = useForm({});

    const process = ClassesApi.update(classId, "addAttendee");

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values,
            onComplete: () => {

                form.reset({ userId: "", role: "" });

                handleRefresh();

            }
        });

    }, [process.run, classId, handleRefresh]);


    return useMemo(() => (

        <div className='add-attendee-form'>

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
                autoDiscardChanges={true}
            >

                <Row gutter={8} edge={true}>

                    <Col className='col-search' grow={1}>

                        <FieldRelationship
                            form={form}
                            name='studentId'
                            required={true}
                            model={'Students'}
                            preload={true}
                            defaultValue={form.defaults.studentId}
                            placeholder={"Select Student To Add"}
                            format={["fullName", "danceStylesById." + styleId + ".role", "danceStylesById." + styleId + ".level"]}
                        />

                    </Col>

                    {format === "partner" &&
                        <Col className='col-role'>

                            <FieldRadio
                                form={form}
                                name='role'
                                defaultValue={form.defaults.role}
                                options={[
                                    { label: "Follow", value: "follow" },
                                    { label: "Lead", value: "lead" },
                                ]}
                            />

                        </Col>
                    }

                    <Col className='col-add' shrink={1}>

                        <FieldWrap>
                            <Button
                                label={"Add"}
                                loading={process.loading}
                                size='regular'
                            />
                        </FieldWrap>

                    </Col>

                </Row>

            </Form>

        </div>

    ), [classId, form.hash, process.hash]);

}

export default ClassesAttendeesAddForm;
