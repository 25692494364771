import { dateTime } from "@dex/bubl-helpers";

export function dayDateTime(date, time = true) {

    if (!date) return "";

    if (typeof date === "object" && date.str) date = date.str;

    let format = "dddd, D MMM YYYY";

    if (time) format += ", h:mm A";

    return dateTime(date, format);

}