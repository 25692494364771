import React, { useEffect, useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import StudentsApi from '../StudentsApi';
import { Button, Space } from '@dex/bubl-dash';
import TimelineCard from '../../../elements/TimelineCard/TimelineCard';

const StudentsSingleTimeline: React.FC<any> = (props: any) => {

    const { id } = props;

    const [page, setPage] = useState(0);
    const [count, setCount] = useState({ pages: 1, page: 0 });
    const [events, setEvents] = useState<any>([]);

    const timeline = StudentsApi.getTimeline({
        timeout: 30000,
    });

    useEffect(() => {

        if (page === 0 && events.length > 0) setEvents([]);

        const limit = 40;

        timeline.run({
            params: {
                where: { studentId: id },
                // format: "private",
                include: [
                    {
                        relation: 'record',
                    },
                    {
                        relation: 'class',
                        scope: { include: [{ relation: "group", preset: "link" }] }
                    }
                ],
                order: ["date DESC"],
                limit: limit,
                skip: page * limit
            },
            onComplete: (response) => {

                setCount(response.count);

                setEvents((current) => {

                    return [...current, ...response.data];

                })

            }
        });


    }, [id, page]);

    return useMemo(() => (


        <Panel
            heading={"Timeline"}
        >

            {events.map((event) => (
                <React.Fragment key={event.idKey}>

                    <TimelineCard
                        data={event}
                    />

                    <Space height={2} />

                </React.Fragment>
            ))}

            {count.pages > count.page &&
                <>
                    <Space />
                    <Button
                        onClick={setPage.bind(null, page + 1)}
                        label={"Load More"}
                        loading={timeline.loading}
                        disabled={timeline.loading}
                        type={"primary"}
                        block
                    />
                </>
            }

        </Panel>

    ), [id, timeline.hash]);

}

export default StudentsSingleTimeline;
