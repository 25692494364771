import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import UserCreditsClassesAttended from './UserCreditsClassesAttended';
import { Space } from '@dex/bubl-dash';

const UserCreditsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Credits Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Student",
                                key: "student",
                                format: "ModelLink"
                            },
                            {
                                label: "Product",
                                key: "product",
                                format: "ModelLink"
                            },
                            data.type === "order" && {
                                label: "Order",
                                key: "order",
                                format: "ModelLink",
                            },
                            data.type === "description" && {
                                label: "Description",
                                key: "description",
                                format: "nl2br",
                                hideIfEmpty: true
                            },
                        ]}
                        columnB={[
                            {
                                label: "Credits Issued",
                                key: "issuedCredits",
                                format: "number",
                                suffix: " credits",
                                hideIfEmpty: true
                            },
                            {
                                label: "Credits Left",
                                key: "leftCredits",
                                format: "number",
                                suffix: " credits",
                                hideIfEmpty: true
                            },
                        ]}
                        columnC={[
                            {
                                label: "Order",
                                key: "order",
                                format: "modelLink",
                            },
                            {
                                label: "Date Purchased",
                                key: "created.date",
                                format: "dateDay",
                                hideIfEmpty: true
                            },
                        ]}
                        columnD={[
                            {
                                label: "First Use Date",
                                key: "firstUseDate",
                                format: "dateDay",
                                hideIfEmpty: true
                            },
                            {
                                label: "Expiry Date",
                                key: "expiryDate",
                                format: "dateDay",
                                hideIfEmpty: true
                            },
                        ]}
                    />

                </Panel>

                <Space />
                <UserCreditsClassesAttended data={data} />

            </Col>

        </Row>

    ), [data]);

}

export default UserCreditsSingleView;
