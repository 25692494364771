import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import OrdersApi from '../OrdersApi';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import { FieldDate, FieldNumber, FieldText, FieldTextArea, FieldUpload } from '@dex/bubl-dash';

const OrderStageMakePayment: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({ ...data, amountPaid: data.amountPaid || data.total });

    const process = OrdersApi.update(data.id, "stageMakePayment");

    const reverse = OrdersApi.update(data.id, "stageReverse");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"makePayment"}
            heading={"Make Payment"}
            editCaps={["stageMakePaymentOrders"]}
            viewCaps={["viewOrders"]}
            editable={data.paymentStatus === "failed" || data.paymentStatus === "pending"}
            showForm={data.paymentStatus === "pending"}
            showMeta={data.paymentMethod}
            passed={data.paymentMethod}
            showSave={form.changed}
            reverse={false}
            last_stage={"last_stage"}
            columnA={[
                {
                    label: "Date Paid",
                    key: "datePaid",
                    format: "date",
                },
                {
                    label: "Discount",
                    key: "discount",
                    format: "number",
                    prefix: "RM ",
                    hideIfEmpty: true
                },
                {
                    label: "Amount Paid",
                    key: "amountPaid",
                    format: "number",
                    prefix: "RM "
                },

            ].filter(Boolean)}
            columnB={[
                {
                    label: "Payment Method",
                    key: "paymentMethod",
                    format: "intl",
                    prefix: "orders.paymentMethod",

                },
                {
                    label: "Payment Notes",
                    key: "paymentNotes",
                    format: "nl2br",
                    hideIfEmpty: true
                },
            ].filter(Boolean)}
            columnC={[
                {
                    label: "Reference ID",
                    key: "referenceId",
                    hideIfEmpty: true
                },
                {
                    label: "Payment Slip",
                    key: "paymentSlip",
                    format: "file",
                    hideIfEmpty: true
                },
            ].filter(Boolean)}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldRadio
                                        form={form}
                                        label={"Payment Method"}
                                        name='paymentMethod'
                                        required={true}
                                        defaultValue={form.defaults.paymentMethod}
                                        options={[
                                            { label: "Cash", value: "cash" },
                                            { label: "Direct Bank Transfer", value: "directBankTransfer" },
                                            { label: "FPX Online Transfer", value: "onlineTransfer" },
                                        ]}
                                    />

                                </Col>

                                {form.values.paymentMethod &&
                                    <>
                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldDate
                                                form={form}
                                                label={"Date"}
                                                name='datePaid'
                                                required={true}
                                                defaultValue={form.defaults.datePaid}
                                            />

                                        </Col>
                                        {(form.values.paymentMethod === "directBankTransfer" || form.values.paymentMethod === "onlineTransfer") &&
                                            <Col col={{ xs: 24, md: 12 }}>

                                                <FieldText
                                                    form={form}
                                                    label={"Referrence ID"}
                                                    name='referenceId'
                                                    required={true}
                                                    defaultValue={form.defaults.referenceId}
                                                />

                                            </Col>
                                        }
                                        <Col col={{ xs: 24, md: 24 }}>

                                            <FieldTextArea
                                                form={form}
                                                label={"Payment Notes"}
                                                name='paymentNotes'
                                                required={false}
                                                defaultValue={form.defaults.paymentNotes}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldNumber
                                                form={form}
                                                label={"Amount Paid"}
                                                name='amountPaid'
                                                required={true}
                                                prefix={"RM "}
                                                value={form.values.amountPaid}
                                                decimalScale={2}
                                            />

                                        </Col>

                                        {/* <Col col={{ xs: 24, md: 12 }}>

                                            <FieldNumber
                                                form={form}
                                                label={"Discount"}
                                                name='discount'
                                                required={false}
                                                prefix={"RM "}
                                                defaultValue={form.defaults.discount}
                                                decimalScale={2}
                                            />

                                        </Col> */}

                                        <Col col={{ xs: 24, md: 24 }}>
                                            <FieldUpload
                                                form={form}
                                                folder={'admin-media'}
                                                label={"Payment Slip"}
                                                name={'paymentSlip'}
                                                required={false}
                                                defaultValue={form.defaults.paymentSlip}
                                                accept={"image/*"}
                                                limit={1}
                                            />
                                        </Col>

                                    </>
                                }

                            </Row>

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default OrderStageMakePayment;
