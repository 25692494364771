import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';

import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldBlocks from '../../../components/ContentBlocks/FieldBlocks';


const PagesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Pages Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Title"}
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Description"}
                                    name='description'
                                    required={false}
                                    defaultValue={form.defaults.description}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldRelationship
                                    key={form.values.section}
                                    form={form}
                                    label={"Parent Page"}
                                    name='parentId'
                                    required={false}
                                    defaultValue={form.defaults.parentId}
                                    where={{ section: form.values.section, hasParent: false, ...(mode === "edit") ? { id: { neq: data.id } } : {} }}
                                    endpoint={'Pages'}
                                    preload={true}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldSwitch
                                    form={form}
                                    label="Has Content"
                                    name="hasContent"
                                    defaultValue={form.defaults.hasContent}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Section"}
                                    name='section'
                                    required={true}
                                    defaultValue={form.defaults.section}
                                    options={[
                                        { value: "other", label: "Other" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status"}
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { value: "draft", label: "Draft" },
                                        { value: "preview", label: "Preview" },
                                        { value: "live", label: "Live" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Thumbnail"}
                                    name={`thumbnail`}
                                    required={false}
                                    defaultValue={form.defaults.thumbnail}
                                    limit={1}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                {form.values.hasContent &&
                    <>

                        <Seperator
                            heading={"Content"}
                            top={"auto"}
                            bottom={"auto"}
                        />

                        <FieldBlocks
                            form={form}
                            name={`contentBlocks`}
                            defaultValue={form.defaults.contentBlocks}
                        />

                    </>
                }

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    ), [props]);

}

export default PagesCreateEditForm;
