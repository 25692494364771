import PagesIndex from './PagesIndex';
import PagesCreate from './PagesCreate';
import PagesSettings from './PagesSettings';
import PagesSingle from './PagesSingle';

export default function (app) {

    const settings = PagesSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 14,
        items: [
            {
                label: "All Pages",
                path: settings.path,
                caps: settings.indexCaps,
            },
            // {
            //     label: "About",
            //     path: settings.path + "/section/about",
            //     caps: settings.indexCaps
            // },
            {
                label: "Other",
                path: settings.path + "/section/other",
                caps: settings.indexCaps
            },
            {
                label: "Re-Order",
                path: settings.path + "/reorder",
                caps: settings.createCaps
            },
            /*{
                label: "Draft",
                path: settings.path + "/status/draft",
                caps: settings.indexCaps
            },
            {
                label: "Preview",
                path: settings.path + "/status/preview",
                caps: settings.indexCaps
            },
            {
                label: "Live",
                path: settings.path + "/status/live",
                caps: settings.indexCaps
            },*/
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            }
        ],
    });

    PagesIndex(app, settings);

    PagesCreate(app, settings);

    PagesSingle(app, settings);

}