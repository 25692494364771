import React from 'react';
import { titleCase, get, date, time } from "@dex/bubl-helpers";
import ClassesApi from './ClassesApi';
import { Status, WhiteSpace, useApp } from '@dex/bubl-dash';
import { dayDateTime } from '../../functions/formats';
import AttendanceBar from '../../components/AttendanceBar/AttendanceBar';
import moment from 'moment';

let settings;

export default settings = {
    key: 'classes',
    zone: 'studio',
    path: '/classes',
    title: "Classes",
    endpoint: ClassesApi.endpoint,
    modelName: 'Classes',
    idKey: 'id',
    primaryKey: 'title',
    indexCaps: ["viewClasses"],
    viewCaps: ["viewClasses"],
    updateCaps: ["updateClasses"],
    createCaps: ["createClasses"],
    actions: ['trash'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [

            {
                label: "This Week",
                path: settings.path + "/date/week",
                caps: settings.viewCaps
            },
            {
                label: "Today",
                path: settings.path + "/date/today",
                caps: settings.viewCaps
            },
            {
                label: "Upcoming",
                path: settings.path + "/date/upcoming",
                caps: settings.viewCaps
            },
            {
                label: "Past",
                path: settings.path + "/date/past",
                caps: settings.viewCaps
            },
            {
                label: "All",
                path: settings.path,
                caps: settings.viewCaps
            },
            {
                label: "Add New",
                caps: settings.createCaps,
                path: settings.path + "/create"
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: ClassesApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = {
            where: {},
            include: [
                {
                    relation: 'group',
                    preset: "link"
                },
                {
                    relation: 'style',
                    preset: "link"
                },
            ],
            order: ['created.date DESC'], limit: 10, skip: 0
        };

        if (params && params.batch) {

            filters.where.batch = params.batch;

            if (!params.order) filters.order = ['dateTime.str ASC'];

        }

        if (params && params.date) {

            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            const formattedCurrent = date(currentDate, "YYYY-MM-DD")

            if (params.date === 'today') {

                const endOfDay = new Date(currentDate);
                endOfDay.setHours(23, 59, 59, 999);
                const formattedEnd = date(endOfDay, "YYYY-MM-DD")

                filters.where.date = {
                    "between": [formattedCurrent, formattedEnd]
                };

                if (!params.order) filters.order = ['dateTime.str ASC'];

            } else if (params.date === 'week') {

                const startOfWeek = moment(currentDate).startOf("isoWeek");
                const endOfWeek = moment(currentDate).endOf("isoWeek");

                const formattedStart = date(startOfWeek, "YYYY-MM-DD");
                const formattedEnd = date(endOfWeek, "YYYY-MM-DD");

                filters.where.date = {
                    "between": [formattedStart, formattedEnd]
                };

                if (!params.order) filters.order = ['dateTime.str ASC'];

            } else if (params.date === 'upcoming') {

                const startOfNextDay = new Date(currentDate);
                startOfNextDay.setDate(currentDate.getDate() + 1);
                const formattedStartNextDay = date(startOfNextDay, "YYYY-MM-DD")

                filters.where.date = {
                    "gte": formattedStartNextDay
                };

                if (!params.order) filters.order = ['dateTime.str ASC'];

            } else if (params.date === 'past') {

                const dayBefore = new Date(currentDate);
                dayBefore.setDate(currentDate.getDate() - 1);
                const formattedDayBefore = date(dayBefore, "YYYY-MM-DD")

                filters.where.date = {
                    "lte": formattedDayBefore
                };

                if (!params.order) filters.order = ['dateTime.str DESC'];

            }
        }

        return filters;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Style",
                key: "styleId",
                type: "relationship",
                model: "Styles"
            },
            {
                label: "Group",
                key: "groupId",
                type: "relationship",
                model: "Groups"
            },
            {
                label: "Type",
                key: "type",
                type: "choice",
                options: [
                    { value: "class", label: "Class" },
                    { value: "event", label: "Event" },
                    { value: "workshop", label: "Workshop" },
                    { value: "social", label: "Social" },
                    { value: "practice", label: "Practice" },
                ]
            },
            // {
            //     label: "Allowed Passes",
            //     key: "allowedPassesIds",
            //     type: "relationship",
            //     model: "Products"
            // },
            // {
            //     label: "Retake Passes",
            //     key: "retakePassesIds",
            //     type: "relationship",
            //     model: "Products"
            // },
            {
                label: "Date Time",
                key: "dateTime",
                type: "date",
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Day",
                key: "dateTime.str",
                render: (value) => date(value, "dddd"),
                style: { width: 90 }
            },
            {
                label: "Date",
                key: "dateTime.str",
                format: "date",
                sort: "dateTime.str",
                style: { width: 90 }
            },
            {
                label: "Time",
                key: "dateTime.str",
                render: (value) => time(value),
                style: { width: 90 }
            },
            {
                label: "Group",
                key: "group",
                format: "ModelLink"
            },
            {
                label: "Type",
                key: "type",
                format: "status",
                prefix: "classes.type",
            },
            {
                label: "Attendance",
                key: "attendingTotal",
                format: "number",
                render: (value, row) => (
                    <AttendanceBar data={row} indicator={""} />
                )
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: ClassesApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: ClassesApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = {
            include: [
                {
                    relation: 'group',
                    scope: {
                        include: [
                            {
                                relation: 'allowedPasses',
                                preset: "link"
                            },
                            {
                                relation: 'retakePasses',
                                preset: "link"
                            }
                        ]
                    }
                },
                {
                    relation: 'style',
                    preset: "link"
                },
                {
                    relation: 'allowedPasses',
                    preset: "link"
                },
                {
                    relation: 'retakePasses',
                    preset: "link"
                }
            ]
        };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: any = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');
        const app = useApp();

        if (error) title = "Error";
        else if (data) title =
            <>
                {[data.group.title, dayDateTime(data.dateTime.str)].join(" - ")}
                <WhiteSpace />
                <Status prefix='classes.type' status={data.type} />
                {data.isCancelled && (
                    <>
                        <WhiteSpace />
                        <Status prefix="classes.status" status={"cancelled"} />
                    </>
                )}
            </>;
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        const data = fetch?.data || {};

        return [
            {
                label: "View",
                path: path + "/view",
                caps: settings.viewCaps
            },
            !(data.isCancelled === true) && {
                label: "Edit",
                path: path + "/edit",
                caps: settings.updateCaps
            },
            !(data.isCancelled === true) && {
                label: "Cancel",
                path: path + "/cancel",
                caps: settings.updateCaps,
            },

        ].filter(Boolean)

    },
    singleButtons: (routeName, params, user, fetch) => {

        return [
        ].filter(Boolean);

    },
};