import ProductsIndex from './ProductsIndex';
import ProductsCreate from './ProductsCreate';
import ProductsSettings from './ProductsSettings';
import ProductsSingle from './ProductsSingle';
import { USE_CREDITS, USE_PASS } from '../../functions/mode';

export default function (app) {

    const settings = ProductsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 13,
        items: [
            {
                label: "All Products",
                path: settings.path,
                caps: settings.indexCaps,
            },
            USE_PASS && {
                label: "Passes",
                path: settings.path + "/type/pass",
                caps: settings.createCaps
            },
            USE_CREDITS && {
                label: "credits",
                path: settings.path + "/type/credits",
                caps: settings.createCaps
            },
            // {
            //     label: "Tickets",
            //     path: settings.path + "/type/ticket",
            //     caps: settings.createCaps
            // },
            // {
            //     label: "Merch",
            //     path: settings.path + "/type/merch",
            //     caps: settings.createCaps
            // },
        ].filter(Boolean),
    });

    ProductsIndex(app, settings);

    ProductsCreate(app, settings);

    ProductsSingle(app, settings);

}