import React from 'react';
import styles from "./UnpaidBadge.module.scss";
import { RiKnifeBloodLine } from "react-icons/ri";

const UnpaidBadge: React.FC<UnpaidBadgeProps> = (props) => {

    return (
        <RiKnifeBloodLine className={styles.icon} />
    )

}

interface UnpaidBadgeProps {
    [key: string]: any,
}

export default UnpaidBadge;