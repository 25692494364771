import React, { useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { DataTableCell, InputTableRow, ModelLink, Space } from '@dex/bubl-dash';
import InputTable from '@dex/bubl-dash/src/lib/components/InputTable/InputTable';
import { date, get } from '@dex/bubl-helpers';

import AttendanceHistoryMark from '../../../components/AttendanceHistoryMark/AttendanceHistoryMark';
import AttendanceLegend from '../../../components/AttendanceLegend/AttendanceLegend';

const AttendanceTable: React.FC<any> = (props: any) => {

    const { attendees, classes, group, students, handleReset } = props;

    const columns: any = [
        {
            key: "index",
            label: "#",
            style: { width: "40px" },
        },
        {
            key: "name",
            label: "Student",
        },
    ];

    classes.map((item) => {
        columns.push({
            key: item.id,
            label: date(item.date, "DD/MM"),
            desc: date(item.date, "ddd"),
            style: { width: "70px" },
        });
    })

    return useMemo(() => (


        <Panel
            heading={"Attendees - " + (group ? group.title : "") + (students ? students.join(", ") : "")}
        >

            <AttendanceLegend />

            <Space height={16} />

            <InputTable
                columns={columns}
            >

                {attendees.map((attendee, index) => {

                    return (
                        <InputTableRow key={index}>

                            <DataTableCell>{index + 1}</DataTableCell>

                            <DataTableCell>
                                <ModelLink data={{ fullName: attendee.fullName, id: attendee.studentId, modelName: "Students" }} />
                                <br />
                                <small style={{ fontSize: ".9em" }}>{attendee.level}</small>
                            </DataTableCell>

                            {classes.map((item) => {

                                return (
                                    <DataTableCell key={item.id}>

                                        <AttendanceHistoryMark
                                            data={get(attendee, `attendance.${item.id}`, "")}
                                        />

                                    </DataTableCell>
                                )

                            })}

                        </InputTableRow>
                    )
                })}

            </InputTable>

        </Panel>

    ), []);

}

export default AttendanceTable;
