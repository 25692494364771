import React from 'react';
import styles from './AttendanceSummary.module.scss';
import { USE_CREDITS } from '../../functions/mode';
import { howMany } from '@dex/bubl-helpers';

const AttendanceSummary: React.FC<any> = (props: any) => {

    const { data } = props;

    const limitAttendees = data.limitAttendees;

    const totalAttending = data.attendingTotal + data.attendedTotal + data.noshowTotal;
    const leadsAttending = data.attendingLeads + data.attendedLeads + data.noshowLeads;
    const followsAttending = data.attendingFollows + data.attendedFollows + data.noshowFollows;

    const leadsRetake = data.retakeLeads || 0;
    const followsRetake = data.retakeFollows || 0;

    const leadsNoShow = data.noshowLeads || 0;
    const followsNoShow = data.noshowFollows || 0;

    const leadsUnpaid = data.unpaidLeads || 0;
    const followsUnpaid = data.unpaidFollows || 0;

    const leadsSelfService = data.selfServiceLeads || 0;
    const followsSelfService = data.selfServiceFollows || 0;

    return (
        <div className={styles.grid}>

            <AttendanceSummaryCard
                total={totalAttending}
                limit={limitAttendees}
                lead={leadsAttending}
                follow={followsAttending}
                format={data.style.format}
                label={limitAttendees ? "Sign-Ups / Limit" : "Sign-Ups"}
            />

            <AttendanceSummaryCard
                total={leadsNoShow + followsNoShow}
                lead={leadsNoShow}
                follow={followsNoShow}
                format={data.style.format}
                label={"No Shows"}
            />

            <AttendanceSummaryCard
                total={leadsRetake + followsRetake}
                lead={leadsRetake}
                follow={followsRetake}
                format={data.style.format}
                label={"Retakes"}
            />

            <AttendanceSummaryCard
                total={leadsUnpaid + followsUnpaid}
                lead={leadsUnpaid}
                follow={followsUnpaid}
                format={data.style.format}
                label={USE_CREDITS ? "No Credits" : "No Pass"}
            />

            <AttendanceSummaryCard
                total={leadsSelfService + followsSelfService}
                lead={leadsSelfService}
                follow={followsSelfService}
                format={data.style.format}
                label={"Self Service"}
            />

        </div>

    );

};

const AttendanceSummaryCard: React.FC<any> = (props: any) => {

    const { total, limit, lead, follow, label, format } = props;

    return (

        <div className={styles.card}>
            <div className={styles.body}>
                <div className={styles.title}>{total} {limit && `/ ${limit}`}</div>
                {format === "partner" &&
                    <div className={styles.subtitle}>{follow} {howMany(follow, "Follow", "Follows")}  / {lead} {howMany(lead, "Lead", "Leads")}</div>
                }
                {format === "solo" &&
                    <div className={styles.subtitle}>{total} {howMany(total, "Attendee", "Attendees")}</div>
                }
            </div>
            <div className={styles.footer}>
                <h1 className={styles.label}>{label}</h1>
            </div>
        </div>

    );

}

export default AttendanceSummary;
