import React, { useEffect, useRef, useState } from 'react';
import styles from "./ScheduleCalendar.module.scss";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';
import DashboardApi from '../../views/Dashboard/DashboardApi';
import { dateDay } from '@dex/bubl-helpers';
import { Loading, MediaImage, useApp, useMountEffect } from '@dex/bubl-dash';
import ClassesSettings from '../../views/Classes/ClassesSettings';

const ScheduleCalendar: React.FC<ScheduleCalendarProps> = (props) => {

    const app = useApp();
    const [nav] = app.store('nav');

    const [ready, setReady] = useState(false);

    const [date, setDate] = useState(dateDay(new Date()));
    const [events, setEvents] = useState([]);

    const ref = useRef(null);

    const fetch = DashboardApi.getEvents();

    useEffect(() => {

        setEvents([]);
        fetch.cancel();

        fetch.run({
            params: { date: date, },
            onComplete: (response) => {
                setEvents(response);
            }
        });

    }, [date]);

    useMountEffect(() => {

        const timer = setTimeout(() => {

            setReady(true);

        }, 1000);

        return () => clearTimeout(timer);

    });

    function handleEventClick(clickInfo: any) {
        const route = { "type": "Navigation/NAVIGATE", routeName: ClassesSettings.key + "View", params: { id: clickInfo.event.id } }
        app.navigate(nav, 'repalce', route);
    }

    function handleDateChange(event) {

        const date = moment(event.start);

        setDate(dateDay(date.add(2, "weeks")));

    }

    return (

        <div className={styles.container}>

            {!ready &&
                <div className={styles.loading}>
                    <Loading />
                </div>
            }

            {ready &&
                <FullCalendar
                    ref={ref}
                    headerToolbar={{
                        left: 'prev',
                        center: 'title',
                        right: 'next'
                    }}
                    plugins={[dayGridPlugin]}
                    initialView='dayGridMonth'
                    weekends={true}
                    events={events}
                    dayHeaderFormat={{ weekday: 'long' }}
                    eventContent={renderEventContent}
                    eventTimeFormat={{
                        hour: 'numeric',
                        minute: '2-digit',
                        meridiem: 'short'
                    }}
                    firstDay={1}
                    eventClick={handleEventClick}
                    contentHeight={"auto"}
                    datesSet={handleDateChange}
                />
            }

        </div>

    )

}

function renderEventContent(item: any) {

    function getTime(time: any, suffix = false) {

        if (!time) return;

        const append = suffix ? 'h:mm A' : "h:mm";

        if (time.indexOf("T12:00:00") > -1) return "Noon";
        if (time.indexOf("T00:00:00") > -1) return "Midnight";

        return moment(time).format(append);;

    };

    const props = item.event.extendedProps;

    return (

        <>

            {props.isNewType &&

                <div className={styles.sep}>

                    {props.icon &&
                        <>
                            <MediaImage
                                className={styles.icon}
                                type={'thumb'}
                                resizeMode={"contain"}
                                filters={{ width: 200 }}
                                id={props.icon.fileId}
                            />
                        </>
                    }

                    {!props.icon &&
                        <div className={styles.text}>
                            {props.style}
                        </div>
                    }

                </div>

            }

            <div className={[styles.item, styles["item-type-" + props.type], "bg-" + props.color].join(" ")}>

                <div className={[styles.inner, item.event.title ? "" : styles.innerCenter].join(" ")}>

                    {item.event.title &&
                        <p className={styles.title}>{item.event.title}</p>
                    }

                    <p className={styles.time}>{getTime(item.event.startStr, false)} - {getTime(item.event.endStr, true)}</p>

                </div>

            </div>

        </>

    )
}

interface ScheduleCalendarProps {
    [key: string]: any,
}

export default ScheduleCalendar;