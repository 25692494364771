import React, { useCallback, useMemo } from 'react';
import { Heading, Space, useApp, useForm, useMountEffect } from '@dex/bubl-dash';
import GroupsApi from '../../Groups/GroupsApi';
import GroupsCreateEditForm from '../../Groups/Components/GroupsCreateEditForm';

const StylesSingleGroupsEdit: React.FC<any> = (props: any) => {

    const { id, handleRefresh } = props;

    const group = GroupsApi.getOne(id);

    useMountEffect(() => {

        group.run();

    })

    return useMemo(() => (

        <>

            {group.data &&
                <StylesSingleGroupsEditForm
                    data={group.data}
                    handleRefresh={handleRefresh}
                />
            }

        </>

    ), [group.hash]);

}

const StylesSingleGroupsEditForm: React.FC<any> = (props: any) => {

    const { id, data, handleRefresh } = props;

    const app = useApp();

    const form = useForm(data);

    const update = GroupsApi.update(data.id, "update", {
        onComplete: (data) => {

            app.alert('Class Group Saved', 'success');
            handleRefresh();

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [id]);

    return useMemo(() => (

        <>

            <Heading>Edit Class Group: {data.title}</Heading>

            <GroupsCreateEditForm
                mode={"edit"}
                form={form}
                data={data}
                handleSubmit={handleSubmit}
                process={update}
            />

        </>

    ), []);

}

export default StylesSingleGroupsEdit;
