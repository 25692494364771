import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import AttendeesApi from '../AttendeesApi';
import AttendeesCreateEditForm from './AttendeesCreateEditForm';

const AttendeesSingleEdit: React.FC<any> = (props: any) => {

    const app = useApp();

    const { data, handleClose } = props;

    const form = useForm(data);

    const update = AttendeesApi.update(data.id, "update", {
        onComplete: (data) => {

            app.alert('Attendees Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({
            data: values,
            onComplete: (response) => {
                if (handleClose) handleClose();
            }
        });

    }, [props]);

    return useMemo(() => (

        <AttendeesCreateEditForm
            mode={"edit"}
            form={form}
            data={data}
            handleSubmit={handleSubmit}
            process={update}
            handleClose={handleClose}
        />

    ), [data, form.hash, update]);

}

export default AttendeesSingleEdit;
