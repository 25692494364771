import React, { useMemo } from 'react';
import { DataTable, DataTableCell, InputTableRow, ModelLink, useApp } from '@dex/bubl-dash';
import { formatNum, titleCase } from '@dex/bubl-helpers';

const OrdersLineItems: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const columns = [
        {
            label: "Type",
        },
        {
            label: "Item",
        },
        {
            label: "Cost (RM)",
            alignEnd: true,
        },
        {
            label: "Quantity",
            alignMiddle: true,
        },
        {
            label: "Subtotal (RM)",
            alignEnd: true,
        },
    ];

    return useMemo(() => (

        <>

            <DataTable
                data={null}
                columns={columns}
                className={"mini-table order-table"}
            >

                {data.items.map((item: any, index: number) => (
                    <InputTableRow className={"invoice-line-item"}>

                        <DataTableCell className={"invoice-line-desc"}>

                            {item.type ? titleCase(item.type) : ""}

                        </DataTableCell>

                        <DataTableCell style={{ width: "30%" }} className={"invoice-line-desc"}>

                            {item.productTitle ? item.productTitle : ""}  {item.size ? " - " + item.size : ""}

                            {item.studentId && item.studentName &&
                                <>
                                    <br />
                                    Student: <ModelLink data={{ id: item.studentId, fullName: item.studentName, modelName: "Students" }} />
                                </>
                            }

                            {item.classId && item.class &&
                                <>
                                    <br />
                                    Class: <ModelLink data={{ id: item.classId, title: item.title, modelName: "Classes" }} />
                                </>
                            }

                        </DataTableCell>

                        <DataTableCell alignEnd className={"invoice-line-quantity"}>

                            {formatNum(item.price, 2, "")}

                        </DataTableCell>

                        <DataTableCell alignMiddle className={"invoice-line-value"}>

                            x {item.quantity ? item.quantity : ""}

                        </DataTableCell>

                        <DataTableCell alignEnd className={"invoice-line-value"}>

                            {formatNum(item.total, 2, "")}


                        </DataTableCell>

                    </InputTableRow>
                ))}

                <InputTableRow>

                    <DataTableCell style={{ width: "10%" }} colSpan={4} alignEnd className="invoice-line-label">
                        <strong>Total</strong>
                    </DataTableCell>

                    <DataTableCell style={{ width: "10%" }} alignEnd>
                        <strong>{formatNum(data.total, 2, "RM ")}</strong>
                    </DataTableCell>

                </InputTableRow>


            </DataTable>

        </>

    ), [data]);

}

export default OrdersLineItems;