import React from 'react';
import { titleCase, get, isEmpty } from "@dex/bubl-helpers";
import StudentsApi from './StudentsApi';
import { MediaImage, Status, WhiteSpace } from '@dex/bubl-dash';
import { USE_CREDITS } from '../../functions/mode';
import CreditsBalance from '../../elements/CreditsBalance/CreditsBalance';
import { render } from '@fullcalendar/core/preact';


let settings;

export default settings = {
    key: 'students',
    zone: 'studio',
    path: '/students',
    title: "Students",
    endpoint: StudentsApi.endpoint,
    modelName: 'Students',
    idKey: 'id',
    primaryKey: 'fullName',
    indexCaps: ["viewStudents"],
    viewCaps: ["viewStudents"],
    createCaps: ["createStudents"],
    actions: ['trash'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Add New",
                path: settings.path + "/create"
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: StudentsApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = {
            where: {},
            include: [
                {
                    relation: 'user',
                    preset: "link"
                }
            ],
            order: ['created.date DESC'],
            limit: 10,
            skip: 0
        };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id', 'fullName'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Student's Name",
                key: "fullName",
                type: "text",
            },
            {
                label: "User Account",
                key: "userId",
                type: "relationship",
                model: "Users"
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "",
                key: "photo",
                format: "image",
                // link: true,
                filters: { width: 44, height: 44 },
                style: { width: 44 }
            },
            {
                label: "Student's Name",
                key: "this",
                format: "ModelLink",
            },
            USE_CREDITS && {
                label: "Credits Left",
                key: "creditsBalance",
                style: { width: 160 },
                render: (value, row) => (
                    <span style={{ display: "inline-block" }}>
                        <CreditsBalance balance={value} />
                    </span>
                )
            },
            {
                label: "Dance Styles",
                key: "danceStyles",
                render: (value, row) => (
                    <>
                        {value?.map((item, key) => (
                            <p key={item.id}>
                                <strong>{item.name}:</strong> {item.role && titleCase(item.role)} {item.level && `(${item.level})`}
                            </p>
                        ))}
                    </>
                )
            },
            // {
            //     label: "Last 5 Weeks",
            //     key: "last5Weeks",
            //     render: (value, row) => (
            //         <>
            //             Activity Over Last 5 Weeks
            //         </>
            //     )
            // },
            {
                label: "User Account",
                key: "user",
                format: "ModelLink"
            },
            // {
            //     label: "Created Date",
            //     key: "created",
            //     format: "OnAndBy",
            //     sort: "created.date",
            //     alignEnd: true
            // },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: StudentsApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: StudentsApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = {
            include: [
                {
                    relation: 'user',
                    preset: "link"
                }
            ]
        };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: any = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";


        if (data) {

            const balance = get(data, 'creditsBalance');

            title = (
                <>

                    {data.photo &&
                        <>
                            <MediaImage
                                id={data.photo}
                                filters={{ width: 40, height: 40 }}
                                alt={data.fullName}
                                className={"image-circle"}
                                link={true}
                            />

                            <WhiteSpace />
                        </>
                    }

                    {data.fullName}

                    {USE_CREDITS && !isEmpty(balance) &&
                        <>
                            <WhiteSpace /> <CreditsBalance balance={balance} />
                        </>
                    }

                </>
            );

        }

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view",
                caps: settings.viewCaps
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.createCaps
            },
        ]

    },
    singleButtons: (routeName, params, user, fetch) => {

        return [
        ].filter(Boolean);

    },
};