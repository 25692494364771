import React, { useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';

import styles from "./StudentCard.module.scss";
import { MediaImage } from '@dex/bubl-dash';
import Link from '@dex/bubl-dash/src/lib/components/Link/Link';
import { titleCase } from '@dex/bubl-helpers';
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import CreditsBalance from '../CreditsBalance/CreditsBalance';

const StudentCard: React.FC<StudentCardProps> = (props) => {

    const { data } = props;

    return (

        <>
            <Panel heading={false} className={styles.card}>

                <div className={styles.photo}>

                    {data.photo &&
                        <MediaImage
                            id={data.photo}
                            filters={{ width: 60, height: 60 }}
                            alt={data.fullName}
                            className={"image-circle"}
                            link={"/students/" + data.id + "/view"}
                            target={"_self"}
                        />
                    }

                </div>

                <Link
                    path={"/students/" + data.id + "/view"}
                    className={styles.name}
                >
                    {data.fullName}
                </Link>

                {data.connection &&
                    <p className={styles.connection}>{titleCase(data.connection)}</p>
                }

                <Space height={12} />

                <span style={{ display: "inline-block" }}>
                    <CreditsBalance balance={data.creditsBalance} />
                </span>

                <Space height={12} />

                {data.danceStyles && data.danceStyles.length > 0 &&
                    <div className={styles.list}>
                        {data.danceStyles?.map((item, key) => (
                            <p key={item.id}>
                                <strong>{item.name}:</strong> {item.role && titleCase(item.role)} {item.level && `(${item.level})`}
                            </p>
                        ))}
                    </div>
                }

            </Panel>
        </>

    );

}

interface StudentCardProps {
    [key: string]: any,
}

export default StudentCard;