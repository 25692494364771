import UsersSingleViewMain from "./UsersSingleViewMain";
import UsersSingleEditMain from "./UsersSingleEditMain";
// import UsersCreateEditForm from "./Components/UsersCreateEditForm";
import CreditsBalance from "../../elements/CreditsBalance/CreditsBalance";

import { get, isEmpty } from "@dex/bubl-helpers";
import { WhiteSpace } from "@dex/bubl-dash";
import { USE_CREDITS } from "../../functions/mode";
import { render } from "@fullcalendar/core/preact";

export default function (app) {

    // app.mods.UsersCreateEditForm = UsersCreateEditForm;

    app.mods.UsersSingleViewMain = UsersSingleViewMain;
    app.mods.UsersSingleEditMain = UsersSingleEditMain;

    app.mods.UsersSettings = (settings) => {

        if (settings._singleTitle) return settings;

        settings._singleTitle = settings.singleTitle;

        // settings.singleTitle = (routeName, params, user, fetch) => {

        //     let title = settings._singleTitle(routeName, params, user, fetch);

        //     if (USE_CREDITS) {

        //         const balance = get(fetch, 'data.creditsBalance');

        //         if (!isEmpty(balance)) {
        //             title = <>{title} <WhiteSpace /> {<CreditsBalance balance={balance} />}</>
        //         }

        //     }

        //     return title;

        // };

        // settings._indexColumns = settings.indexColumns;

        // settings.indexColumns = (routeName, params, user) => {

        //     let columns = settings._indexColumns(routeName, params, user);

        //     if (USE_CREDITS) {

        //         columns.splice(3, 0, {
        //             key: "creditsBalance",
        //             label: "Credits Balance",
        //             sort: "creditsBalance",
        //             style: { width: 80 },
        //             render: (value) => (
        //                 <CreditsBalance balance={value} />
        //             )
        //         });

        //     }

        //     return columns;

        // };



        return settings;

    }

}