import React from 'react';

import styles from "./CreditsBalance.module.scss";
import { formatNum, howMany } from '@dex/bubl-helpers';
import { WhiteSpace } from '@dex/bubl-dash';
import { RiCoinsFill } from "react-icons/ri";

const CreditsBalance: React.FC<CreditsBalanceProps> = (props) => {

    const { balance } = props;

    let classNames = [styles.balance];

    if (balance < 0) classNames.push(styles.negative);
    else if (balance === 0) classNames.push(styles.empty);

    return (

        <div className={classNames.join(" ")}>

            <RiCoinsFill className={styles.icon} /><WhiteSpace />
            {formatNum(balance)} {howMany(balance, "Credit", "Credits")}

        </div>

    )

}

interface CreditsBalanceProps {
    [key: string]: any,
}

export default CreditsBalance;