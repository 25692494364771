import { apiGet, baseApi, baseApiReturn, options, response } from "@dex/bubl-fetch";

const endpoint = 'Students';

const StudentsApi: studentsApiReturn | baseApiReturn = baseApi(endpoint);

StudentsApi.getByUserId = (userId: string, options?: options): response => {

    return apiGet({
        "url": endpoint + "/getByUserId/" + userId,
        ...options
    });

}

StudentsApi.getTimeline = (options?: options): response => {

    return apiGet({
        "url": "/Timelines",
        ...options
    });

}

interface studentsApiReturn extends baseApiReturn {
    getByUserId: (id: string, options?: options) => response;
    getTimeline: (options?: options) => response;
}

export default StudentsApi;