import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import OrdersApi from '../OrdersApi';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import { FieldNumber, FieldText, FieldTextArea } from '@dex/bubl-dash';

const OrderStageVerifyPayment: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({ ...data, amountPaid: data.amountPaid || data.total });

    const process = OrdersApi.update(data.id, "stageVerifyPayment");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"verifyPayment"}
            heading={"Verify Payment"}
            editCaps={["stageVerifyPaymentOrders"]}
            viewCaps={["viewOrders"]}
            editable={false}
            showForm={data.paymentStatus === "processing"}
            showMeta={data.verifyPaymentOn}
            passed={data.paymentStatus === "paid" || data.paymentStatus === "failed"}
            showSave={form.changed}
            last_stage={"last_stage"}
            columnA={[
                {
                    label: "Payment Verified Amount",
                    key: "verifiedAmount",
                    format: "number",
                    prefix: "RM ",
                },
            ].filter(Boolean)}
            columnB={[
                {
                    label: "Payment Reference ID",
                    key: "verifiedReferenceId",
                    format: "number",
                    prefix: "RM ",
                },
            ].filter(Boolean)}
            columnC={[
                {
                    label: "Payment Verified Notes",
                    key: "verifiedNotes",
                    format: "nl2br",
                    hideIfEmpty: true
                },
            ].filter(Boolean)}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldRadio
                                        form={form}
                                        label={"Payment Status"}
                                        name='paymentStatus'
                                        required={true}
                                        defaultValue={form.defaults.paymentStatus}
                                        options={[
                                            { label: "Processing", value: "processing" },
                                            { label: "Pending", value: "pending" },
                                            { label: "Failed", value: "failed" },
                                            { label: "Paid", value: "paid" },
                                        ]}
                                    />

                                </Col>

                                {form.values.paymentStatus === "paid" &&
                                    <>
                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldNumber
                                                form={form}
                                                label={"Payment Verified Amount"}
                                                name='verifiedAmount'
                                                required={true}
                                                defaultValue={form.defaults.verifiedAmount}
                                                prefix={"RM "}
                                                value={form.values.amountPaid}
                                                decimalScale={2}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"Payment Verified Referrence Id"}
                                                name='verifiedReferrenceId'
                                                required={false}
                                                defaultValue={form.defaults.verifiedReferrenceId}
                                            />

                                        </Col>
                                    </>
                                }

                                <Col col={{ xs: 24, md: 24 }}>

                                    <FieldTextArea
                                        form={form}
                                        label={"Payment Verified Notes"}
                                        name='verifiedNotes'
                                        required={false}
                                        defaultValue={form.defaults.verifiedNotes}
                                    />

                                </Col>

                            </Row>

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default OrderStageVerifyPayment;
