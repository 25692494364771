import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import { Line, Seperator } from '@dex/bubl-dash';
import StatusDot from '../../../elements/StatusDot/StatusDot';
import { USE_CREDITS, USE_PASS } from '../../../functions/mode';

const GroupsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Class Group Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Style",
                                key: "style.title",
                            },
                        ]}
                        columnB={[

                        ]}
                        columnC={[

                        ]}
                    />


                    <>
                        <Seperator
                            top={"auto"}
                            bottom={"auto"}
                            heading={"Calendar Display"}
                        />

                        <MetaTable
                            data={data}
                            columnA={[

                                {
                                    label: "Label",
                                    key: "calendarLabel",
                                    format: "titleCase"
                                },
                            ]}
                            columnB={[
                                {
                                    label: "Color",
                                    key: "calendarColor",
                                    render: (value, row) => (
                                        <StatusDot color={row.calendarColor} />
                                    )
                                },
                            ]}
                            columnC={[
                                {
                                    label: "Icon",
                                    key: "calendarIcon",
                                    format: "file"
                                },
                            ]}
                        />

                    </>

                    <>
                        <Seperator
                            top={"auto"}
                            bottom={"auto"}
                            heading={"Class Defaults"}
                        />

                        <MetaTable
                            data={data}
                            columnA={[
                                {
                                    label: "Duration",
                                    key: "duration",
                                    format: "number",
                                    suffix: " mins"
                                },
                                {
                                    label: "Location",
                                    key: "location",
                                },
                                {
                                    label: "Details",
                                    key: "details",
                                    format: "html",
                                },
                            ]}
                            columnB={[
                                {
                                    label: "Limit Leads",
                                    key: "limitLeads",
                                },
                                {
                                    label: "Limit Follows",
                                    key: "limitFollows",
                                },
                                {
                                    label: "Limit Attendees",
                                    key: "limitAttendees",
                                },
                            ]}
                            columnC={[
                                USE_CREDITS && {
                                    label: "Credits To Attend Class",
                                    key: "creditsAttend",
                                    format: "number",
                                    suffix: " credits"
                                },
                                USE_CREDITS && data.creditsRetake && {
                                    label: "Credits To Retake Class",
                                    key: "creditsRetake",
                                    format: "number",
                                    suffix: " credits",
                                },
                                USE_PASS && {
                                    label: "Allowed Passes",
                                    key: "allowedPasses",
                                    format: "ModelLink",
                                    join: <br />
                                },
                                USE_PASS && data.type === "class" && {
                                    label: "Retake Passes",
                                    key: "retakePasses",
                                    format: "ModelLink",
                                    join: <br />
                                },
                            ].filter(Boolean)}
                        />

                    </>


                </Panel>

            </Col>

        </Row >

    ), [data]);

}

export default GroupsSingleView;
