import React from 'react';
import styles from "./TimelineCard.module.scss";
import { Row, Col, Status, WhiteSpace } from '@dex/bubl-dash';
import { dayDateTime } from '../../functions/formats';
import ModelLink from '@dex/bubl-dash/src/lib/components/ModelLink/ModelLink';
import AttendanceHistoryMark from '../../components/AttendanceHistoryMark/AttendanceHistoryMark';
import { howMany } from '@dex/bubl-helpers';

const TimelineCard: React.FC<TimelineCardProps> = (props) => {

    const { data } = props;

    return (

        <div className={styles.card}>

            <Row gutter={8} edge={true}>

                <Col className={styles.colDate} col={{ xs: 24, sm: 14, md: 8 }} justify='center'>
                    {data.event === "attend" &&
                        <>
                            <p className={styles.date}>{dayDateTime(data?.class?.dateTime)}</p>
                            <ModelLink data={{ id: data?.classId, title: data?.class?.group?.title }} modelName="Classes" />
                        </>
                    }
                    {data.event === "addCredits" &&
                        <>
                            <p className={styles.date}>{dayDateTime(data.date)}</p>
                            <ModelLink data={data.record} />
                        </>
                    }
                    {data.event === "graduate" &&
                        <>
                            <p className={styles.date}>{dayDateTime(data.date, false)}</p>
                            Graduated From {data.record?.label}
                        </>
                    }
                </Col>

                <Col className={styles.colStatus} col={{ xs: 24, sm: 10, md: 8 }} justify='center'>

                    {data.event === "attend" &&
                        <Row align='center'>

                            <div>
                                <Status prefix={"attendees.status"} status={data.record.status} />
                                <WhiteSpace />
                            </div>

                            <AttendanceHistoryMark data={data.record} />

                        </Row>
                    }

                    {data.event === "addCredits" &&
                        <>
                            <Status prefix={"pass.status"} status={data.record.status} />
                        </>
                    }

                    {data.event === "graduate" &&
                        <>

                        </>
                    }

                </Col>

                <Col className={styles.colDeta} col={{ xs: 24, sm: 24, md: 8 }} justify='center'>
                    {data.event === "attend" &&
                        <>
                            {data.record.creditsCost} Credits Used
                        </>
                    }
                    {data.event === "addCredits" &&
                        <>
                            {data.record.issuedCredits} {howMany(data.record.leftCredits, "Credit", "Credits")} Issued / {data.record.usedCredits} Used /  {data.record.leftCredits} {data.record.status === "expired" ? "Expired" : "Left"}
                        </>
                    }
                    {data.event === "graduate" &&
                        <>

                        </>
                    }
                </Col>

            </Row>

        </div>

    );

}

interface TimelineCardProps {
    [key: string]: any,
}

export default TimelineCard;