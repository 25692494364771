import React, { useEffect, useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import ClassesApi from '../ClassesApi';
import ClassesAttendeesAddForm from './ClassesAttendeesAddForm';
import AttendeeCard from '../../../elements/AttendeeCard/AttendeeCard';
import { Events, useUpdateEffect } from '@dex/bubl-dash';

const ClassesAttendees: React.FC<any> = (props: any) => {

    const { data } = props;

    const [key, setKey] = useState(0);

    const attendees = ClassesApi.format(data.id, "attendees");

    useEffect(() => {

        attendees.run();

    }, [key]);

    useUpdateEffect(() => {

        Events.emit("refresh", null);

    }, [key]);

    return useMemo(() => (

        <Panel
            heading={"Attendees"}
        >

            <ClassesAttendeesAddForm
                classId={data.id}
                styleId={data.styleId}
                format={data.style?.format}
                handleRefresh={setKey.bind(null, key + 1)}
            />

            {attendees.data && attendees.data.length > 0 &&
                <>
                    {attendees.data.map((attendee, index) => (
                        <AttendeeCard
                            key={attendee.id}
                            attendee={attendee}
                            data={data}
                            refreshOnChange={true}
                            refreshAttendeesList={setKey.bind(null, key + 1)}
                        />
                    ))}
                </>
            }

        </Panel>

    ), [data.id, key, attendees.hash]);

}

export default ClassesAttendees;
