import React, { useMemo } from 'react';
import OrderStageMakePayment from './OrderStageMakePayment';
import OrderStageVerifyPayment from './OrderStageVerifyPayment';


const OrderStages: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>
            <OrderStageMakePayment
                data={data}
            />

            {(data.paymentStatus !== "pending" || data.verifyPaymentOn) &&
                <OrderStageVerifyPayment
                    data={data}
                />
            }
        </>

    ), [data]);

}

export default OrderStages;
