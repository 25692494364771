import React, { useCallback, useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Button, ButtonRow, Col, DataTableCell, FieldCheckbox, FieldDate, FieldHidden, Form, InputTableCell, InputTableRow, Line, Row, Space, useApp, useForm } from '@dex/bubl-dash';
import InputTable from '@dex/bubl-dash/src/lib/components/InputTable/InputTable';
import { date, dateDay, get } from '@dex/bubl-helpers';

import AttendanceHistoryMark from '../../../components/AttendanceHistoryMark/AttendanceHistoryMark';
import AttendanceLegend from '../../../components/AttendanceLegend/AttendanceLegend';
import GroupsApi from '../../Groups/GroupsApi';

const GraduationPromote: React.FC<any> = (props: any) => {

    const { attendees, classes, group, handleReset } = props;

    const form = useForm({ attendees: [], groupId: group.id, date: dateDay(new Date()), });

    const process = GroupsApi.create({}, "graduationPromote");

    const app = useApp();

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values,
            onComplete: (response) => {

                handleReset();

                app.alert("Student's Promoted", "success");

            },
        });

    }, []);

    const columns: any = [
        {
            key: "index",
            label: "#",
            style: { width: "40px" },
        },
        {
            key: "name",
            label: "Select Students",
        },
        {
            key: "level",
            label: "Current Level",
        }
    ];

    classes.map((item) => {
        columns.push({
            key: item.id,
            label: date(item.date, "DD/MM"),
            desc: date(item.date, "ddd"),
            style: { width: "70px" },
        });
    })

    return useMemo(() => (


        <Panel
            heading={"Promote Students - " + group.title}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <FieldHidden
                    form={form}
                    name='groupId'
                    defaultValue={form.defaults.groupId}
                />

                <AttendanceLegend hideRetake />

                <Space height={16} />

                <InputTable
                    columns={columns}
                >

                    {attendees.map((attendee, index) => {

                        return (
                            <InputTableRow key={index}>

                                <DataTableCell>{index + 1}</DataTableCell>

                                {attendee.hasGraduated &&
                                    <DataTableCell>{attendee.fullName}</DataTableCell>
                                }

                                {!attendee.hasGraduated &&
                                    <InputTableCell>

                                        <FieldCheckbox
                                            form={form}
                                            name={`attendees[${index}]`}
                                            defaultValue={""}
                                            singular={true}
                                            options={[{ label: attendee.fullName, value: attendee.studentId }]}
                                        />

                                    </InputTableCell>
                                }

                                <DataTableCell>{attendee.level}</DataTableCell>

                                {classes.map((item) => {

                                    return (
                                        <DataTableCell key={item.id}>

                                            <AttendanceHistoryMark
                                                data={get(attendee, `attendance.${item.id}`, "")}
                                            />

                                        </DataTableCell>
                                    )

                                })}

                            </InputTableRow>
                        )
                    })}

                </InputTable>

                <Space />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldDate
                            form={form}
                            name={"date"}
                            label={"Graduation Date"}
                            required={true}
                            defaultValue={form.defaults.date}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Promote"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={handleReset}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    ), [form.hash, process.loading]);

}

export default GraduationPromote;
