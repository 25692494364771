import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import { Button, Line, Seperator, Space } from '@dex/bubl-dash';
import ClassesAttendees from './ClassesAttendees';
import { dayDateTime } from '../../../functions/formats';
import AttendanceBar from '../../../components/AttendanceBar/AttendanceBar';
import AttendanceSummary from '../../../components/AttendanceSummary/AttendanceSummary';
import { clone } from '@dex/bubl-helpers';
import { USE_CREDITS, USE_PASS } from '../../../functions/mode';

const ClassesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const group = clone(data.group);

    if (data.customize?.includes("passes")) {
        group.allowedPasses = data.allowedPasses;
        group.retakePasses = data.retakePasses;
    }

    if (data.customize?.includes("limits")) {
        group.limitLeads = data.limitLeads;
        group.limitFollows = data.limitFollows;
    }

    if (data.customize?.includes("details")) {
        group.details = data.details;
    }

    const [showDetails, setShowDetails] = useState(false);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Class Details"}
                    actions={(
                        <>
                            <Button
                                label={showDetails ? "Hide More Details" : "Show More Details"}
                                size={"small"}
                                type={"faded"}
                                onClick={setShowDetails.bind(null, !showDetails)}
                            />
                        </>
                    )}
                >

                    {showDetails &&
                        <>

                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: "Class Group",
                                        key: "group",
                                        format: "ModelLink",
                                    },
                                ]}
                                columnB={[
                                    {
                                        label: "Date Time",
                                        key: "dateTime",
                                        render: dayDateTime,
                                    },
                                ]}
                                columnC={[

                                    {
                                        label: "Duration",
                                        key: "duration",
                                        format: "number",
                                        suffix: " mins"
                                    }
                                ]}
                            />

                            <Line />

                            <MetaTable
                                data={group}
                                columnA={[
                                    USE_CREDITS && {
                                        label: "Credits To Attend Class",
                                        key: "creditsToAttend",
                                        format: "number",
                                        suffix: " credits"
                                    },
                                    USE_CREDITS && {
                                        label: "Credits To Retake Class",
                                        key: "creditsToRetake",
                                        format: "number",
                                        suffix: " credits"
                                    },
                                    USE_PASS && {
                                        label: "Allowed Passes",
                                        key: "allowedPasses",
                                        format: "ModelLink",
                                        join: <br />
                                    },
                                    USE_PASS && {
                                        label: "Retake Passes",
                                        key: "retakePasses",
                                        format: "ModelLink",
                                        join: <br />
                                    },
                                ]}
                                columnB={[
                                    {
                                        label: "Limit Leads",
                                        key: "limitLeads",
                                    },
                                    {
                                        label: "Limit Follows",
                                        key: "limitFollows",
                                    },
                                ]}
                                columnC={[
                                    {
                                        label: "Location",
                                        key: "location",
                                    },
                                    {
                                        label: "Details",
                                        key: "details",
                                        format: "html"
                                    },
                                ]}
                            />

                            <Line />

                        </>
                    }

                    <>

                        <AttendanceBar data={data} indicator={"indicator"} />

                        <Space />

                        <AttendanceSummary data={data} />

                    </>

                    {data.isCancelled === true &&
                        <>
                            <Seperator
                                heading="Cancellation Details"
                                top="auto"
                                bottom="auto"
                            />

                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: "Cancellation Reason",
                                        key: "cancelClassReason",
                                        format: "nl2br",
                                    },

                                ]}
                                columnB={[
                                    {
                                        label: "Cancellation On",
                                        key: "isCancelledOn",
                                        format: "OnAndBy",
                                    },
                                ]}
                            />
                        </>
                    }

                </Panel>

                <Space />

                <ClassesAttendees data={data} />

            </Col>

        </Row>

    ), [data, showDetails]);

}

export default ClassesSingleView;
