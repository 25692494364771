import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldAutocomplete, FieldCheckbox, FieldDate, FieldList, FieldNumber, FieldRadio, FieldRelationship, FieldRich, FieldTextTime, FieldWrap } from '@dex/bubl-dash';
import { USE_CREDITS, USE_PASS } from '../../../functions/mode';
import moment from 'moment';
import { clone, dateTime } from '@dex/bubl-helpers';


const ClassesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const handleRepeat = useCallback((value, index, form) => {

        console.log(value, index);
        const date = moment(value);

        const dates = clone(form.values.dates);

        dates.splice(index + 1, 0, date.add(1, "week").format("YYYY-MM-DDTHH:MM:SS"))

        form.handleChange({ name: "dates", value: dates, reset: true })

    }, [])

    return useMemo(() => (

        <Panel
            heading={"Class Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Class Group"}
                                    name='groupId'
                                    required={true}
                                    model={'Groups'}
                                    preload={true}
                                    defaultValue={form.defaults.groupId}
                                    where={{ status: "active" }}
                                />

                            </Col>

                            {mode === "create" &&

                                <Col col={{ xs: 24, md: 24 }}>

                                    <FieldList
                                        form={form}
                                        name={'dates'}
                                        required={true}
                                        label='Date & Time'
                                        addButtonLabel={"Add Date & Time"}
                                        defaultValues={""}
                                    >

                                        {form.values.dates.map((row, index) => (

                                            <Row key={form.key + "-" + index} gutter={8} edge={true}>

                                                <Col col={{ xs: 18, md: 16 }}>

                                                    <FieldDate
                                                        form={form}
                                                        name={`dates[${index}]`}
                                                        required={true}
                                                        defaultValue={row}
                                                        time={true}
                                                    />

                                                </Col>

                                                <Col col={{ xs: 6, md: 8 }}>

                                                    {row && index + 1 === form.values.dates.length &&
                                                        <FieldWrap>
                                                            <Button
                                                                label={"Repeat Next Week"}
                                                                onClick={handleRepeat.bind(null, row, index, form)}
                                                                type={"light"}
                                                            />
                                                        </FieldWrap>
                                                    }

                                                </Col>

                                            </Row>

                                        ))}

                                    </FieldList>

                                </Col>
                            }

                            {mode === "edit" &&
                                <>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldDate
                                            form={form}
                                            label={"Date & Time"}
                                            name='dateTime'
                                            required={true}
                                            defaultValue={form.defaults.dateTime}
                                            time={true}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 6 }}>

                                        <FieldNumber
                                            form={form}
                                            label={"Duration"}
                                            name='duration'
                                            suffix={" mins"}
                                            required={true}
                                            defaultValue={form.defaults.duration}
                                            decimalScale={0}
                                        />

                                    </Col>

                                </>
                            }

                        </Row>
                    </Col>

                </Row>

                {mode === "edit" &&
                    <Line />
                }

                {mode === "edit" &&
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldCheckbox
                                form={form}
                                label={"Customzie"}
                                name='customize'
                                defaultValue={form.defaults.customize}
                                options={[
                                    USE_PASS && { label: "Passes", value: "passes" },
                                    // USE_CREDITS && { label: "Credits", value: "credits" },
                                    { label: "Limits", value: "limits" },
                                    { label: "Details", value: "details" },
                                ].filter(Boolean)}
                            />

                        </Col>

                        {(form.values.customize?.includes("limits") || form.values.customize?.includes("details")) &&
                            <Col col={{ xs: 24, md: 16 }}>

                                <Row gutter={8} edge={true}>

                                    {form.values.customize?.includes("limits") &&
                                        <>
                                            <Col col={{ xs: 24, md: 8 }}>

                                                <FieldNumber
                                                    form={form}
                                                    label={"Limit Leads"}
                                                    name='limitLeads'
                                                    required={false}
                                                    defaultValue={form.defaults.limitLeads}
                                                    decimalScale={0}
                                                    placeholder='#'
                                                />

                                            </Col>
                                            <Col col={{ xs: 24, md: 8 }}>

                                                <FieldNumber
                                                    form={form}
                                                    label={"Limit Follows"}
                                                    name='limitFollows'
                                                    required={false}
                                                    defaultValue={form.defaults.limitFollows}
                                                    decimalScale={0}
                                                    placeholder='#'
                                                />

                                            </Col>
                                            <Col col={{ xs: 24, md: 8 }}>

                                                <FieldNumber
                                                    form={form}
                                                    label={"Limit Total Attendees"}
                                                    name='limitAttendees'
                                                    required={false}
                                                    defaultValue={form.defaults.limitAttendees}
                                                    decimalScale={0}
                                                    placeholder='#'
                                                />

                                            </Col>
                                        </>
                                    }

                                    {form.values.customize?.includes("details") &&
                                        <>
                                            <Col col={{ xs: 24, md: 24 }}>

                                                <FieldAutocomplete
                                                    form={form}
                                                    label={"Location"}
                                                    name='location'
                                                    group={"location"}
                                                    required={false}
                                                    defaultValue={form.defaults.location}
                                                />

                                            </Col>
                                            <Col col={{ xs: 24, md: 24 }}>

                                                <FieldRich
                                                    form={form}
                                                    label={"Details"}
                                                    name='details'
                                                    required={false}
                                                    defaultValue={form.defaults.details}
                                                />

                                            </Col>
                                        </>
                                    }

                                </Row>

                            </Col>
                        }

                        {USE_PASS && form.values.customize?.includes("passes") &&

                            <Col col={{ xs: 24, md: 8 }}>

                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 24, md: 24 }}>

                                        <FieldRelationship
                                            form={form}
                                            label={"Allowed Passes"}
                                            name='allowedPassesIds'
                                            required={false}
                                            model={'Products'}
                                            preload={true}
                                            defaultValue={form.defaults.allowedPassesIds}
                                            isMulti={true}
                                            where={{ type: "pass", status: "active" }}
                                        />

                                    </Col>

                                    {data.group.type === "class" &&
                                        <Col col={{ xs: 24, md: 24 }}>

                                            <FieldRelationship
                                                form={form}
                                                label={"Retake Passes"}
                                                name='retakePassesIds'
                                                required={false}
                                                model={'Products'}
                                                preload={true}
                                                defaultValue={form.defaults.retakePassesIds}
                                                isMulti={true}
                                                where={{ type: "pass", status: "active" }}
                                            />

                                        </Col>
                                    }

                                </Row>

                            </Col>
                        }

                    </Row>
                }


                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel >

    ), [props, process.loading, form.hash]);

}

export default ClassesCreateEditForm;
