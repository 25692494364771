import UserCreditsIndex from './UserCreditsIndex';
import UserCreditsCreate from './UserCreditsCreate';
import UserCreditsSettings from './UserCreditsSettings';
import UserCreditsSingle from './UserCreditsSingle';

export default function (app) {

    const settings = UserCreditsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 13,
        items: [
            {
                label: "All Credits",
                path: settings.path,
                caps: settings.indexCaps,
            },
            {
                label: "Active Credits",
                path: settings.path + "/status/active",
                caps: settings.indexCaps,
            },
            {
                label: "Empty Credits",
                path: settings.path + "/status/empty",
                caps: settings.indexCaps,
            },
            {
                label: "Expired Credits",
                path: settings.path + "/status/expired",
                caps: settings.indexCaps,
            },
        ],
    });

    UserCreditsIndex(app, settings);

    UserCreditsCreate(app, settings);

    UserCreditsSingle(app, settings);

}