import StudentsIndex from './StudentsIndex';
import StudentsCreate from './StudentsCreate';
import StudentsSettings from './StudentsSettings';
import StudentsSingle from './StudentsSingle';

export default function (app) {

    const settings = StudentsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 13,
        items: [
            {
                label: "All Students",
                path: settings.path,
                caps: settings.indexCaps,
            },
        ],
    });

    StudentsIndex(app, settings);

    StudentsCreate(app, settings);

    StudentsSingle(app, settings);

}