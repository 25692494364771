import React from 'react';
import styles from "./SelfServiceBadge.module.scss";
import { FaStar } from "react-icons/fa";

const SelfServiceBadge: React.FC<SelfServiceBadgeProps> = (props) => {

    return (
        <FaStar className={styles.icon} />
    )

}

interface SelfServiceBadgeProps {
    [key: string]: any,
}

export default SelfServiceBadge;