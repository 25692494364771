import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldRadio, FieldSwitch, MediaImage, Status, WhiteSpace } from '@dex/bubl-dash';

const AttendeesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, handleClose } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={<>
                {data.student.photo &&
                    <>
                        <MediaImage
                            id={data.student.photo}
                            filters={{ width: 40, height: 40 }}
                            alt={data.fullName}
                            className={"image-circle"}
                            link={true}
                        /><WhiteSpace />
                    </>
                }
                {data.student.fullName}
                <WhiteSpace /><Status prefix={"attendees.role"} status={data.role} />
                <WhiteSpace /><Status prefix={"attendees.status"} status={data.status} />
            </>}
            actions={<>
                {handleClose &&
                    <Button
                        label={"Close"}
                        onClick={handleClose}
                        size='regular'
                        type='faded'
                    />
                }
            </>}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    {/* <Col col={{ xs: 24, md: 12 }}>

                        <FieldRadio
                            form={form}
                            name={"status"}
                            label={"Status"}
                            defaultValue={data.status}
                            options={[
                                { label: "Attending", value: "attending" },
                                { label: "Attended", value: "attended" },
                                { label: "No Show", value: "noshow" },
                            ]}
                        />

                    </Col> */}

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRadio
                            form={form}
                            name={"role"}
                            label={"Role"}
                            defaultValue={form.defaults.role}
                            required={true}
                            options={[
                                { label: "Follow", value: "follow" },
                                { label: "Lead", value: "lead" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldSwitch
                            form={form}
                            name={"isSelfService"}
                            label={"Self Service"}
                            defaultValue={form.defaults.isSelfService}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default AttendeesCreateEditForm;
