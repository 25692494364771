import React, { useMemo } from 'react';

import styles from "./TimelineAttendeeCard.module.scss";
import { Col, ModelLink, Row, Status, WhiteSpace } from '@dex/bubl-dash';
import { dayDateTime } from '../../functions/formats';
import { USE_CREDITS, USE_PASS } from '../../functions/mode';
import SelfServiceBadge from '../SelfServiceBadge/SelfServiceBadge';
import AttendanceHistoryMark from '../../components/AttendanceHistoryMark/AttendanceHistoryMark';

const TimelineAttendeeCard: React.FC<TimelineAttendeeCardProps> = (props) => {

    const { attendce, showPass } = props;

    return useMemo(() => (

        <div className={styles.card}>

            <Row gutter={8} edge={true}>

                <Col className={styles.colDate} col={{ xs: 24, sm: 14, md: 8 }} justify='center'>

                    <p className={styles.date}>{dayDateTime(attendce?.class?.dateTime)}</p>
                    <ModelLink data={{ id: attendce?.classId, title: attendce?.class?.group?.title }} modelName="Classes" />

                </Col>

                <Col className={styles.colStatus} col={{ xs: 24, sm: 10, md: 8 }} justify='center'>
                    <Row align='center'>

                        <div>
                            <Status prefix={"attendees.status"} status={attendce.status} />
                            <WhiteSpace />
                        </div>

                        <AttendanceHistoryMark data={attendce} />

                    </Row>

                </Col>

                <Col className={styles.colDeta} col={{ xs: 24, sm: 24, md: 8 }} justify='center'>

                    {USE_CREDITS &&
                        <div className={'status-grid'}>

                            {!attendce.hasCredits &&
                                <Status prefix={"attendces.pass"} status={"unpaid"} />
                            }

                            {attendce.hasCredits &&
                                <>
                                    {attendce.creditsIds.map((id, index) => (
                                        <Status prefix={"attendees.pass"} status={"has-credits"} label={<>-{attendce.creditsUsed[id]} Credits From <ModelLink data={{ id: id, modelName: "UserCredits" }} /></>} />
                                    ))}
                                </>
                            }

                        </div>
                    }

                    {USE_PASS &&
                        <>

                            {!attendce.hasPass &&
                                <Status prefix={"attendees.pass"} status={"no-pass"} />
                            }

                            {attendce.hasPass && attendce.isRetake &&
                                <Status prefix={"attendees.pass"} status={"retake"} />
                            }

                            {attendce.hasPass && !attendce.isRetake &&
                                <Status prefix={"attendees.pass"} status={"has-pass"} />
                            }

                            {attendce.hasPass && showPass &&
                                <>
                                    <WhiteSpace /> <WhiteSpace />
                                    <ModelLink data={attendce.pass} />
                                </>
                            }

                        </>

                    }

                </Col>

            </Row>

        </div>

    ), [attendce]);

}

interface TimelineAttendeeCardProps {
    [key: string]: any,
}

export default TimelineAttendeeCard;