import React, { useCallback, useMemo, useState } from 'react';
import styles from "./AttendeeCard.module.scss";
import { Button, ButtonGroup, Col, Events, Link, Modal, ModelLink, Row, Status, WhiteSpace } from '@dex/bubl-dash';
import AttendeesApi from '../../views/Attendees/AttendeesApi';
import { FiMinusCircle, FiEdit3 } from "react-icons/fi";
import { USE_CREDITS, USE_PASS } from '../../functions/mode';
import AttendeesSingleEdit from '../../views/Attendees/Components/AttendeesSingleEdit';
import AttendanceHistoryMark from '../../components/AttendanceHistoryMark/AttendanceHistoryMark';

const AttendeeCard: React.FC<AttendeeCardProps> = (props) => {

    const { data, refreshOnChange, refreshAttendeesList } = props;

    const [attendee, setAttendee] = useState(props.attendee);

    const [edit, setEdit] = useState(false);

    const update = AttendeesApi.update(props.attendee.id, "updateStatus");

    const setStatus = useCallback((status) => {

        update.run({
            data: { status: status },
            onComplete: (response) => {

                setAttendee(response);

                if (refreshOnChange) Events.emit("refresh", null);

            }
        })

    }, []);

    const handleClose = useCallback(() => {

        setEdit(false);

        if (refreshOnChange) Events.emit("refresh", null);
        if (refreshAttendeesList) refreshAttendeesList();

    }, [refreshAttendeesList]);

    const status = attendee?.status;

    return useMemo(() => (

        <>
            {attendee &&
                <div className={styles.card}>

                    <Row gutter={8} edge={true}>

                        <Col className={styles.colName} col={{ xs: 22, sm: 8, md: 8 }} justify='center'>

                            <Link
                                onClick={setStatus.bind(null, "removed")}
                                className={styles.remove}
                                loading={update.loading}
                                title={"Remove Attendee"}
                                confirm={{
                                    title: "Remove " + attendee.student.fullName,
                                    message: "Are you sure you want to remove this attendee?",
                                }}
                            >
                                <FiMinusCircle />
                            </Link>

                            <WhiteSpace />
                            <AttendanceHistoryMark
                                data={{ ...attendee, status: null }}
                            />
                            <WhiteSpace /><WhiteSpace />

                            <span className={styles.user}>
                                <ModelLink data={attendee.student} />
                                <br />
                                <span>{attendee.level}</span>
                            </span>

                        </Col>

                        <Col className={styles.colEdit} col={{ xs: 2, sm: 2, md: 2 }} grow={1} align='end'>

                            {edit &&
                                <Modal
                                    show={edit}
                                    onClose={setEdit.bind(null, false)}
                                    width={720}
                                >

                                    <AttendeesSingleEdit
                                        data={attendee}
                                        handleClose={handleClose}
                                    />

                                </Modal>
                            }

                            <Button
                                icon={<FiEdit3 />}
                                size='small'
                                type='ghost'
                                title={"Edit Attendee"}
                                onClick={setEdit.bind(null, true)}
                            />

                        </Col>

                        <Col className={styles.colInfo} col={{ xs: 12, sm: 8, md: 8 }} justify='center' align='start'>

                            {USE_CREDITS &&
                                <div className={'status-grid'}>

                                    {attendee.isUnpaid &&
                                        <Status prefix={"attendees.pass"} status={"unpaid"} />
                                    }

                                    {attendee.creditsIds.length > 0 &&
                                        <>

                                            {attendee.creditsIds.map((id, index) => (
                                                <>
                                                    <Status prefix={"attendees.pass"} status={"has-credits"} label={<>-{attendee.creditsUsed[id]} Credits From <ModelLink data={{ id: id, modelName: "UserCredits" }} /></>} />
                                                    <br />
                                                </>
                                            ))}
                                        </>
                                    }

                                </div>
                            }

                            {USE_PASS &&
                                <>

                                    {attendee.isUnpaid &&
                                        <Status prefix={"attendees.pass"} status={"no-pass"} />
                                    }

                                    {attendee.hasPass && !attendee.isRetake &&
                                        <Status prefix={"attendees.pass"} status={"has-pass"} />
                                    }

                                    {attendee.hasPass &&
                                        <>
                                            <WhiteSpace /> <WhiteSpace />
                                            <ModelLink data={attendee.pass} />
                                        </>
                                    }

                                </>

                            }

                        </Col>

                        <Col className={styles.colActions} col={{ xs: 12, sm: 6, md: 6 }} align='end' justify='center'>

                            <ButtonGroup
                                condense={false}
                            >
                                {/* <Button
                                    size={"small"}
                                    label={"Attendding"}
                                    type={status === "attending" ? "primary" : "light"}
                                    loading={update.loading}
                                    onClick={setStatus.bind(null, "attending")}
                                /> */}
                                <Button
                                    size={"small"}
                                    label={"Attended"}
                                    type={status === "attended" ? "primary" : "light"}
                                    loading={update.loading}
                                    onClick={setStatus.bind(null, status === "attended" ? "attending" : "attended")}
                                />
                                <Button
                                    size={"small"}
                                    label={"No Show"}
                                    type={status === "noshow" ? "primary" : "light"}
                                    loading={update.loading}
                                    onClick={setStatus.bind(null, status === "noshow" ? "attending" : "noshow")}
                                />
                            </ButtonGroup>

                        </Col>

                    </Row>

                </div>
            }
        </>

    ), [data, edit, attendee, update.hash]);

}

interface AttendeeCardProps {
    [key: string]: any,
}

export default AttendeeCard;