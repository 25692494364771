import GroupsIndex from './GroupsIndex';
import GroupsCreate from './GroupsCreate';
import GroupsSettings from './GroupsSettings';
import GroupsSingle from './GroupsSingle';
import StylesSettings from '../Styles/StylesSettings';

export default function (app) {

    const settings = GroupsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    GroupsIndex(app, settings);

    GroupsCreate(app, settings);

    GroupsSingle(app, settings);

}