import React, { useCallback, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import GraduationFilter from './GraduationFilter';
import GraduationPromote from './GraduationPromote';
import { useApp } from '@dex/bubl-dash';
import { get } from '@dex/bubl-helpers';

const Graduation: React.FC<any> = (props: any) => {

    const [graduate, setGraduate] = useState<any>(null);

    const app = useApp();

    const [nav] = app.store('nav');

    const filtered = graduate && get(nav, 'currentRoute.state.params.filtered');

    const handleReset = useCallback(() => {

        setGraduate(null);

        app.navigate(nav, 'update', { "filtered": undefined });

    }, [nav]);

    const handleFilter = useCallback((value) => {

        app.navigate(nav, 'update', { "filtered": "1" });

        setGraduate(value);

    }, [nav]);

    return (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                {!filtered &&

                    <GraduationFilter
                        setGraduate={handleFilter}
                    />

                }

                {filtered &&

                    <GraduationPromote
                        attendees={graduate.attendees}
                        classes={graduate.classes}
                        group={graduate.group}
                        handleReset={handleReset}
                    />

                }


            </Col>

        </Row>

    );

}

export default Graduation;
