import React from 'react';
import styles from './AttendanceHistoryMark.module.scss';
import SelfServiceBadge from '../../elements/SelfServiceBadge/SelfServiceBadge';
import PostPaidBadge from '../../elements/PostPaidBadge/PostPaidBadge';
import UnpaidBadge from '../../elements/UnpaidBadge/UnpaidBadge';
import { USE_CREDITS, USE_PASS } from '../../functions/mode';

const AttendanceHistoryMark: React.FC<any> = (props: any) => {

    const { data } = props;

    if (!data) return <></>;

    const classNames = [styles.wrap];

    if (data.isRetake) {

        if (data.role === "lead") classNames.push(styles.bgLeadRetake);
        else if (data.role === "follow") classNames.push(styles.bgFollowRetake);
        else classNames.push(styles.bgSoloRetake);

    } else {

        if (data.role === "lead") classNames.push(styles.bgLead);
        else if (data.role === "follow") classNames.push(styles.bgFollow);
        else classNames.push(styles.bgSolo);

    }

    if (data.status === "noshow") classNames.push(styles.bgNoShow);

    return (

        <div className={classNames.join(" ")}>

            {data.isSelfService && <div className={styles.badgeSelfService}><SelfServiceBadge /></div>}
            {data.isPostPaid && <div className={styles.badgePostPaid}><PostPaidBadge /></div>}
            {data.isUnpaid && <div className={styles.badgeUnPaid}><UnpaidBadge /></div>}

        </div>
    );

};

export default AttendanceHistoryMark;