import React, { useMemo } from 'react';

const UsersSingleEditMain: React.FC<any> = (props: any) => {

    return useMemo(() => (

        <>

        </>

    ), [props]);

}

export default UsersSingleEditMain;
