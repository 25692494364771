import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import { Line, ModelLink, Space } from '@dex/bubl-dash';
import { titleCase } from '@dex/bubl-helpers';
import StudentsSingleTimeline from './StudentsSingleTimeline';

const StudentsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Student Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Gender",
                                key: "gender",
                                format: "titleCase"
                            },
                        ]}
                        columnB={[
                            {
                                label: "Phone",
                                key: "phone"
                            },
                        ]}
                        columnC={[
                            {
                                label: "Email",
                                key: "email"
                            },
                        ]}
                    />

                    <Line />

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Dance Styles",
                                key: "danceStyles",
                                render: (value, row) => (
                                    <>
                                        {value?.map((item, key) => (
                                            <p key={item.id}>
                                                <strong>{item.name}:</strong> {item.role && titleCase(item.role)} {item.level && `(${item.level})`}
                                            </p>
                                        ))}
                                    </>
                                )
                            },
                        ]}
                        columnB={[
                            {
                                label: "User Account",
                                key: "user",
                                render: (value, row) => (
                                    <>
                                        {!value &&
                                            "-"
                                        }
                                        {value &&
                                            <p><ModelLink data={value} /></p>
                                        }
                                        {value && row.connection &&
                                            <>
                                                {titleCase(row.connection)}
                                            </>
                                        }
                                    </>
                                )
                            },
                        ]}
                        columnC={[
                        ]}
                    />

                </Panel>

                <Space />

                <StudentsSingleTimeline
                    id={data.id}
                />

            </Col>

        </Row>

    ), [data]);

}

export default StudentsSingleView;
