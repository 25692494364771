import React, { useEffect } from 'react';
import StudentsApi from '../../Students/StudentsApi';
import { Col, Panel, Row } from '@dex/bubl-dash';
import StudentCard from '../../../elements/StudentCard/StudentCard';

const UsersStudentProfiles: React.FC<any> = (props: any) => {

    const { data } = props;

    const students = StudentsApi.getByUserId(data.id);

    useEffect(() => {

        students.run();

    }, [data.id])

    console.log(students.data);

    return (

        <>

            {students.data && students.data.length > 0 &&
                <Row gutter={"auto"} edge={true}>

                    {students.data.map((student, index) => (
                        <Col key={index} col={{ xs: 24, sm: 12, md: 8, lg: 6, xl: 4 }}>

                            <StudentCard
                                data={student}
                            />

                        </Col>
                    ))}

                </Row>
            }

        </>
    );

}

export default UsersStudentProfiles;
