import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Alert, Button, ButtonGroup, FieldList, Form, Heading, Line, Space, Status, useApp, useForm, WhiteSpace } from '@dex/bubl-dash';
import GroupsApi from '../../Groups/GroupsApi';
import { hashCode, loop, titleCase, unslug } from '@dex/bubl-helpers';
import { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import StylesApi from '../StylesApi';
import { FiEdit3, FiTrash } from 'react-icons/fi';

const StylesSingleGroups: React.FC<any> = (props: any) => {

    const { data, setTab, handleRefresh } = props;

    const [lists, setlists] = useState({});

    const [groupsById, setGroupsById] = useState({});

    const groups = GroupsApi.get();

    useEffect(() => {

        groups.run({
            params: {
                where: { styleId: data.id },
                order: ["type ASC", "order ASC"],
                limit: 200,
            },
            onComplete: (data) => {

                const lists = {};
                const groupsById = {};

                loop(data.data, (item) => {

                    if (!lists[item.type]) lists[item.type] = [];

                    lists[item.type].push(item.id);

                    groupsById[item.id] = item;

                })

                setlists(lists);
                setGroupsById(groupsById);

            }
        })

    }, [])

    return useMemo(() => (

        <>
            {groups.complete &&
                <>

                    {Object.keys(lists).length === 0 &&
                        <>
                            <Space />
                            <Alert
                                type={"warning"}
                                message={"Warning!"}
                                description={"Add a class group in order to schedule a class for this dance style."}
                            />
                        </>
                    }

                    {Object.keys(lists).length > 0 &&
                        <StylesSingleGroupsOrder
                            key={hashCode(lists)}
                            data={data}
                            lists={lists}
                            groups={groupsById}
                            setTab={setTab}
                            handleRefresh={handleRefresh}
                        />
                    }

                </>
            }
        </>

    ), [groups.hash]);

}

const StylesSingleGroupsActions: React.FC<any> = (props: any) => {

    const { setTab, handleRefresh, id, title } = props;

    const remove = GroupsApi.delete(id);

    const handleRemove = useCallback(() => {

        remove.run({
            onComplete: () => {
                handleRefresh();
            }
        })

    }, []);

    return (

        <ButtonGroup>
            <Button
                icon={<FiEdit3 />}
                size={"small"}
                type='light'
                title='Edit Class Group'
                onClick={setTab.bind(null, "edit-" + id)}
            />
            <Button
                icon={<FiTrash />}
                size={"small"}
                type='light'
                title='Delete Class Group'
                confirm={{
                    title: `Delete ` + title,
                    message: "Are your sure you want to perform this action?"
                }}
                onClick={handleRemove}
                loading={remove.loading}
            />
        </ButtonGroup>

    )

}

const StylesSingleGroupsOrder: React.FC<any> = (props: any) => {

    const { data, handleRefresh, lists, groups, setTab } = props;

    const form = useForm({ lists: structuredClone(lists) });

    const update = StylesApi.update(data.id, "orderGroups")

    const app = useApp();

    const handleSave = useCallback((values) => {

        update.run({
            data: values,
            onComplete: () => {

                app.alert('Class Groups Order Saved', 'success');

            }
        });

    }, []);

    return (

        <Form
            form={form}
            onSubmit={handleSave}
            loading={update.loading}
        >

            <Row
                gutter={'auto'}
                edge={true}
            >

                {Object.keys(form.values.lists).map((key) => {

                    const ids = form.values.lists[key];

                    return (

                        <Col key={key} col={{ xs: 24, md: 12 }}>

                            <Space height={"small"} />

                            <Heading space={"small"}>{titleCase(unslug(key))}</Heading>

                            <FieldList
                                form={form}
                                name={"lists." + key}
                                defaultValue={ids}
                                addButtonLabel={"Add " + titleCase(unslug(key))}
                                disableAdd={true}
                                disableRemove={true}
                            >
                                {(ids || []).map((id, i) => {

                                    const item = groups[id] || {};

                                    return (

                                        <React.Fragment
                                            key={i}
                                        >

                                            <Space height={2} />

                                            <Row gutter={4} align='center' className='sortable-item'>

                                                <Col grow={1}>
                                                    <WhiteSpace /><WhiteSpace />
                                                    {item.shortTitle}
                                                </Col>

                                                <Col>
                                                    <Status status={item.status} />
                                                    <WhiteSpace />
                                                </Col>

                                                <Col align='end'>

                                                    <StylesSingleGroupsActions
                                                        id={item.id}
                                                        title={item.shortTitle}
                                                        setTab={setTab}
                                                        handleRefresh={handleRefresh}
                                                    />

                                                </Col>

                                            </Row>

                                            <Space height={2} />

                                        </React.Fragment>

                                    )

                                })}

                            </FieldList>


                        </Col>

                    )

                })}

            </Row>

            <Line />

            <ButtonRow>
                <Button
                    label={"Save Order"}
                    loading={update.loading}
                />
                <Button
                    label={"Reset"}
                    type={"faded"}
                    onClick={form.reset}
                />
            </ButtonRow>

        </Form>

    )

}

export default StylesSingleGroups;
