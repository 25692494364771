import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldNumber, FieldRadio, FieldRelationship, FieldRepeater, FieldTextArea, FieldUpload } from '@dex/bubl-dash';
import { USE_CREDITS, USE_PASS } from '../../../functions/mode';

const ProductsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Product Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Type"}
                                    name='type'
                                    required={true}
                                    defaultValue={form.defaults.type}
                                    options={[
                                        USE_PASS && { label: "Pass", value: "pass" },
                                        USE_CREDITS && { label: "Credits", value: "credits" },
                                        // { label: "Ticket", value: "ticket" },
                                        // { label: "Merch", value: "merch" },
                                    ].filter(Boolean)}
                                    button
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldText
                                    form={form}
                                    label={"Title"}
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                    transform={'capitalize'}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldNumber
                                    form={form}
                                    label={"Price"}
                                    name='price'
                                    required={true}
                                    prefix={"RM "}
                                    defaultValue={form.defaults.price}
                                    decimalScale={2}
                                />


                            </Col>

                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    label={"Description"}
                                    name='description'
                                    required={false}
                                    defaultValue={form.defaults.description}
                                />

                            </Col>

                            {form.values.type === "merch" &&
                                <>

                                    <Col col={{ xs: 24, md: 24 }}>

                                        <FieldRepeater
                                            form={form}
                                            name={'sizes'}
                                            required={true}
                                            addButtonLabel={"Add Sizes"}
                                            defaultValues={{}}
                                        >

                                            {form.values.sizes.map((row, x) => (

                                                <React.Fragment key={form.key + "-" + x}>

                                                    <Row gutter={8} edge={true}>

                                                        <Col col={{ xs: 24, md: 4 }}>

                                                            <FieldText
                                                                form={form}
                                                                label={"Size"}
                                                                name={`sizes[${x}].size`}
                                                                defaultValue={row.size}
                                                                required={true}
                                                                sizes={1}
                                                                transform={'uppercase'}
                                                            />

                                                        </Col>

                                                        <Col col={{ xs: 24, md: 4 }}>

                                                            <FieldNumber
                                                                form={form}
                                                                label={"Price"}
                                                                name={`sizes[${x}].price`}
                                                                defaultValue={row.price}
                                                                required={true}
                                                                prefix={"RM "}
                                                                decimalScale={2}
                                                            />

                                                        </Col>

                                                        <Col col={{ xs: 24, md: 16 }}>

                                                            <FieldRelationship
                                                                form={form}
                                                                label={"Stock SKU"}
                                                                name={`sizes[${x}].stockId`}
                                                                model={'Stocks'}
                                                                preload={true}
                                                                defaultValue={row.stockId}
                                                                required={true}
                                                            />

                                                        </Col>

                                                    </Row>

                                                </React.Fragment>

                                            ))}

                                        </FieldRepeater>

                                    </Col>

                                </>
                            }


                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <Row gutter={8} edge>

                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status"}
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { label: "Active", value: "active" },
                                        { label: "Inactive", value: "inactive" },
                                        { label: "Internal", value: "internal" },
                                    ]}
                                />

                            </Col>

                            {form.values.type === "pass" &&
                                <>
                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldNumber
                                            form={form}
                                            label={"No Of Classes"}
                                            name='noOfClasses'
                                            required={true}
                                            defaultValue={form.defaults.noOfClasses}
                                            decimalScale={0}
                                            placeholder={"0 classes"}
                                            suffix={" classes"}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldNumber
                                            form={form}
                                            label={"No Of Socials"}
                                            name='noOfSocials'
                                            required={true}
                                            defaultValue={form.defaults.noOfSocials}
                                            decimalScale={0}
                                            placeholder={"0 socials"}
                                            suffix={" socials"}
                                        />

                                    </Col>
                                </>
                            }

                            {form.values.type === "merch" &&
                                <>
                                    <Col col={{ xs: 24, md: 24 }}>

                                        <FieldUpload
                                            form={form}
                                            folder={'admin-media'}
                                            label={"Featured Photo"}
                                            name={'photo'}
                                            required={false}
                                            defaultValue={form.defaults.photo}
                                            accept={"image/*"}
                                            limit={1}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 24 }}>

                                        <FieldUpload
                                            form={form}
                                            folder={'admin-media'}
                                            label={"Gallery"}
                                            name={'gallery'}
                                            required={false}
                                            defaultValue={form.defaults.gallery}
                                            accept={"image/*"}
                                            limit={6}
                                        />

                                    </Col>
                                </>
                            }

                            {form.values.type === "credits" &&
                                <>
                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldNumber
                                            form={form}
                                            label={"Credits"}
                                            name='credits'
                                            required={true}
                                            defaultValue={form.defaults.credits}
                                            placeholder={"#"}
                                            decimalScale={0}
                                            suffix=" credits"
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldNumber
                                            form={form}
                                            label={"Days Valid"}
                                            name='daysValid'
                                            required={true}
                                            defaultValue={form.defaults.daysValid}
                                            placeholder={"days"}
                                            suffix={" days"}
                                            decimalScale={0}
                                        />

                                    </Col>
                                </>
                            }

                        </Row>

                    </Col>

                </Row>

                <Row gutter={8} edge={true}>












                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default ProductsCreateEditForm;
