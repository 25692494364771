import React, { useCallback, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import AttendanceFilter from './AttendanceFilter';
import AttendanceTable from './AttendanceTable';
import { Space, useApp } from '@dex/bubl-dash';
import { get } from '@dex/bubl-helpers';

const Attendance: React.FC<any> = (props: any) => {

    const [attendees, setAttendees] = useState<any>(null);

    const app = useApp();

    const [nav] = app.store('nav');

    const filtered = attendees && get(nav, 'currentRoute.state.params.filtered');

    const handleReset = useCallback(() => {

        setAttendees(null);

        app.navigate(nav, 'update', { "filtered": undefined });

    }, [nav]);

    const handleFilter = useCallback((value) => {

        app.navigate(nav, 'update', { "filtered": "1" });

        setAttendees(value);

    }, [nav]);

    return (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <AttendanceFilter
                    setAttendees={handleFilter}
                />

                {filtered &&

                    <>

                        <Space />

                        <AttendanceTable
                            attendees={attendees.attendees}
                            classes={attendees.classes}
                            group={attendees.group}
                            students={attendees.students}
                            handleReset={handleReset}
                        />

                    </>

                }


            </Col>

        </Row>

    );

}

export default Attendance;
