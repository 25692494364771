import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Button, ButtonRow, FieldDate, FieldHidden, FieldRadio, FieldRelationship, FieldSelect, FieldSwitch, Form, Line, useForm } from '@dex/bubl-dash';
import moment from 'moment';
import { dateDay } from '@dex/bubl-helpers';
import GroupsApi from '../../Groups/GroupsApi';

const startDate = dateDay(moment().subtract(2, "weeks").startOf("month"));
const endDate = dateDay(moment().subtract(2, "weeks").endOf("month"));
const isThisMonth = dateDay(moment().startOf("month")) === startDate;

const AttendanceFilter: React.FC<any> = (props: any) => {

    const { setAttendees } = props;

    const form = useForm({ period: isThisMonth ? "this-month" : "last-month", startDate: startDate, endDate: endDate, includeRetakes: true });

    const process = GroupsApi.create({}, "attendanceFilter");

    const handleSubmit = useCallback((values) => {

        setAttendees(null);

        process.run({
            data: values,
            onComplete: (response) => {

                setAttendees(response);

            },
        });

    }, [setAttendees]);

    const onPeriodChange = useCallback((period) => {

        if (typeof period === "object" && period.value) period = period.value;

        if (period === "custom") return;

        let startDate = moment();
        let endDate = moment();

        if (period === "this-month") {

            startDate = startDate.startOf("month");
            endDate = moment(startDate).endOf("month");

        } else if (period === "last-month") {

            startDate = startDate.startOf("month").subtract(1, 'week').startOf("month");
            endDate = moment(startDate).endOf("month");

        } else if (period === "last-3-month") {

            startDate = startDate.startOf("month").subtract(1, 'week').startOf("month");
            endDate = moment(startDate).endOf("month");

            startDate = startDate.subtract(2, "months").startOf("month");

        } else if (period === "current-3-month") {

            startDate = startDate.startOf("month");
            endDate = moment(startDate).endOf("month");

            startDate = startDate.subtract(2, "months").startOf("month");

        }

        form.handleChange({ reset: true, name: "startDate", value: dateDay(startDate) });
        form.handleChange({ reset: true, name: "endDate", value: dateDay(endDate) });

    }, [form.hash])

    const [tab, setTab] = useState("group");

    return useMemo(() => (

        <Panel
            heading={"Class Attendance"}
            setTab={setTab}
            activeTab={tab}
            tabs={[
                {
                    name: "group",
                    label: "Filter By Class Group",
                },
                {
                    name: "student",
                    label: "Filter By Student",
                }
            ]}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
                autoDiscardChanges={true}
            >

                <FieldHidden
                    form={form}
                    name="filterBy"
                    value={tab}
                />

                <Row gutter={8} edge={true}>

                    {tab === "group" &&
                        <>
                            <Col col={{ xs: 12, md: 6 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Dance Style"}
                                    name='styleId'
                                    required={true}
                                    model={'Styles'}
                                    preload={true}
                                    defaultValue={form.defaults.styleId}
                                    where={{ status: "active" }} //, supportsAttendance: true
                                />

                            </Col>

                            <Col col={{ xs: 12, md: 6 }}>

                                <FieldRelationship
                                    key={form.values.styleId}
                                    form={form}
                                    label={"Class Group"}
                                    name='groupId'
                                    required={true}
                                    model={'Groups'}
                                    preload={true}
                                    defaultValue={form.defaults.groupId}
                                    where={{ status: "active", styleId: form.values.styleId }}
                                    order={["type ASC", "order ASC"]}
                                    disabled={form.values.styleId ? false : true}
                                />

                            </Col>
                        </>
                    }

                    {tab === "student" &&
                        <>
                            <Col col={{ xs: 12, md: 12 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Students"}
                                    name='studentIds'
                                    required={true}
                                    model={'Students'}
                                    preload={true}
                                    defaultValue={form.defaults.studentIds}
                                    isMulti={true}
                                />

                            </Col>
                        </>
                    }

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRadio
                            button
                            form={form}
                            label={"Period"}
                            name='period'
                            required={true}
                            defaultValue={form.defaults.period}
                            options={[
                                { value: "this-month", label: "Current Month" },
                                { value: "current-3-month", label: "Current 3 Months" },
                                { value: "last-month", label: "Last Month" },
                                { value: "last-3-month", label: "Last 3 Months" },
                            ]}
                            onChange={onPeriodChange}
                        />

                    </Col>

                </Row>

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 12, md: 6 }}>

                        <FieldDate
                            key={form.values.period}
                            form={form}
                            label={"Start Date"}
                            name='startDate'
                            required={true}
                            defaultValue={form.defaults.startDate}
                        // disabled={form.values.period != "custom"}
                        />

                    </Col>

                    <Col col={{ xs: 12, md: 6 }}>

                        <FieldDate
                            key={form.values.period}
                            form={form}
                            label={"End Date"}
                            name='endDate'
                            required={true}
                            defaultValue={form.defaults.endDate}
                        // disabled={form.values.period != "custom"}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 6 }}>

                        <FieldSwitch
                            form={form}
                            label={"Include Retakes"}
                            name='includeRetakes'
                            defaultValue={form.defaults.includeRetakes}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Filter"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    ), [form.hash, process.loading, tab]);

}

export default AttendanceFilter;
