import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import StylesCreateEditForm from "./Components/StylesCreateEditForm";
import StylesApi from "./StylesApi";
import { SimplePage } from '@dex/bubl-dash';
import Graduation from './Graduation/Graduation';
import Attendance from './Attendance/Attendance';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New Dance Style",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: StylesCreateEditForm,
                getCreate: StylesApi.create,
                getClone: StylesApi.getOne,
                defaults: { calendarColor: "gray", status: "active" },
                successMessage: "Dance Style Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey,
            },
        },
    });

    app.addRoute({
        name: "Graduation",
        zone: settings.zone,
        path: "graduation",
        caps: settings.createCaps,
        component: SimplePage,
        componentProps: {
            header: {
                getTitle: () => "Graduation",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            page: Graduation,
        },
    });

    app.addRoute({
        name: "Attendance",
        zone: settings.zone,
        path: "attendance",
        caps: settings.createCaps,
        component: SimplePage,
        componentProps: {
            header: {
                getTitle: () => "Attendance",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            page: Attendance,
        },
    });


}