import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import { ScreenView, Seperator, Space } from '@dex/bubl-dash';
import Events from '@dex/bubl-dash/src/lib/Events';
import StylesSingleGroups from './StylesSingleGroups';
import StylesSingleGroupsEdit from './StylesSingleGroupsEdit';

const StylesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tab, setTab] = useState("groups");

    const handleRefresh = useCallback(() => {

        setTab("");

        Events.emit('refresh', { background: true });

        setTab("groups");

    }, [])

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Dance Style Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Format",
                                key: "format",
                                format: "titleCase"
                            },
                        ]}
                        columnB={[
                            {
                                label: "Supports Class Graduation",
                                key: "supportsGraduation",
                                format: "switch"
                            },
                        ]}
                        columnC={[
                            {
                                label: "Supports Retake Classes",
                                key: "supportsRetake",
                                format: "switch"
                            },
                        ]}
                    />

                    <Space />

                    <Seperator
                        tabs={[
                            {
                                name: "groups",
                                label: "Class Groups"
                            },
                            {
                                name: "new-group",
                                label: "Add New Class Group"
                            }
                        ]}
                        setTab={setTab}
                        activeTab={tab}
                    />

                    {tab === "groups" &&
                        <>

                            <StylesSingleGroups
                                data={data}
                                handleRefresh={handleRefresh}
                                setTab={setTab}
                            />

                        </>
                    }

                    {tab === "new-group" &&
                        <>
                            <Space />
                            <ScreenView
                                routePath={"groups/create"}
                                routeParams={{
                                    create: {
                                        mode: "new",
                                        defaults: {
                                            styleId: data.id,
                                        },
                                        rowProps: {
                                            gutter: null
                                        },
                                        formProps: {
                                        },
                                        successRouteName: null,
                                        successCallback: handleRefresh,
                                    }
                                }}
                            />
                        </>
                    }

                    {tab.indexOf("edit-") === 0 &&
                        <>

                            <StylesSingleGroupsEdit
                                id={tab.substring(5)}
                                handleRefresh={handleRefresh}
                            />

                        </>
                    }

                </Panel>

            </Col>

        </Row >

    ), [data, tab]);

}

export default StylesSingleView;
