import OrdersIndex from './OrdersIndex';
import OrdersCreate from './OrdersCreate';
import OrdersSettings from './OrdersSettings';
import OrdersSingle from './OrdersSingle';

export default function (app) {

    const settings = OrdersSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 10,
        items: [
            {
                label: "All Orders",
                path: settings.path,
                caps: settings.indexCaps,
            },
            {
                label: "Cart",
                path: settings.path + "/status/cart",
                caps: settings.indexCaps,
            },
            {
                label: "Processing",
                path: settings.path + "/status/processing",
                caps: settings.indexCaps,
            },
            {
                label: "Shipping",
                path: settings.path + "/status/shipping",
                caps: settings.indexCaps,
            },
            {
                label: "Complete",
                path: settings.path + "/status/complete",
                caps: settings.indexCaps,
            },
        ],
    });

    OrdersIndex(app, settings);

    OrdersCreate(app, settings);

    OrdersSingle(app, settings);

}