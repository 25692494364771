import React, { useState } from 'react';
import OrdersSettings from '../../Orders/OrdersSettings';
import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import { loop } from '@dex/bubl-helpers';

const UsersOrderHistory: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const indexFetchParams = (routeName, params, users) => {

        let filters = OrdersSettings.indexFetchParams(routeName, params, users);

        filters.where.userId = data.id;

        return filters;

    }

    const indexColumns = (routeName, params, user) => {

        let columns = OrdersSettings.indexColumns(routeName, params, user);

        loop(columns, (column, index) => {
            if (column.key === "user") delete (columns[index]);
        })

        return columns.filter(Boolean);

    }


    return (

        <>

            <IndexTable
                title={"Orders"}
                hideTrashSwitch={true}
                getFetch={OrdersSettings.indexFetch}
                getColumns={indexColumns}
                preFetchFilters={indexFetchParams}
                routeParams={tableState}
                manageState={setTableState}
                getQuickSearch={OrdersSettings.indexQuickSearch}
                getSearchFields={OrdersSettings.indexSearchFields}
            />

        </>

    );

}

export default UsersOrderHistory;
