import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldCheckbox, FieldDate, FieldRadio, FieldRelationship, FieldRich, FieldSelect, FieldSwitch, FieldUpload, Seperator } from '@dex/bubl-dash';
import { dateDay, get } from '@dex/bubl-helpers';
import moment from 'moment';
import StylesApi from '../../Styles/StylesApi';

const StudentsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const styles = StylesApi.get();

    useEffect(() => {

        styles.run({
            params: {
                where: {},
                limit: 1000,
            }
        })

        return () => {
            styles.cancel();
        }

    }, []);

    return useMemo(() => (

        <Panel
            heading={"Student Details"}
        >

            {styles.data &&
                <Form
                    form={form}
                    onSubmit={handleSubmit}
                    loading={process.loading}
                >

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldText
                                form={form}
                                label={"Student's Name"}
                                name='fullName'
                                required={true}
                                defaultValue={form.defaults.fullName}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldRelationship
                                form={form}
                                label={"User Account"}
                                name='userId'
                                defaultValue={form.defaults.userId}
                                model='Users'
                                preload
                            />

                        </Col>

                        {form.values.userId &&
                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldSelect
                                    form={form}
                                    label={"User's Connection To Student"}
                                    name='connection'
                                    defaultValue={form.defaults.connection}
                                    options={[
                                        { value: "self", label: "Self" },
                                        { value: "parent", label: "Parent" },
                                        { value: "guardian", label: "Guardian" },
                                        { value: "child", label: "Child" },
                                        { value: "sibling", label: "Sibling" },
                                        { value: "partner", label: "Partner" },
                                        { value: "friend", label: "Friend" },
                                    ]}
                                />

                            </Col>
                        }

                    </Row>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldSelect
                                        form={form}
                                        label={"Gender"}
                                        name='gender'
                                        defaultValue={form.defaults.gender}
                                        options={[
                                            { value: "male", label: "Male" },
                                            { value: "female", label: "Female" },
                                            { value: "non-binary", label: "Non-Binary" },
                                            { value: "nil", label: "Prefer Not to Say" },
                                        ]}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldText
                                        form={form}
                                        label={"Phone"}
                                        name='phone'
                                        defaultValue={form.defaults.phone}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldText
                                        form={form}
                                        label={"Email Address"}
                                        name='email'
                                        defaultValue={form.defaults.email}
                                    />

                                </Col>

                            </Row>

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldUpload
                                        form={form}
                                        folder={'user-media'}
                                        label={"Photo"}
                                        name={'photo'}
                                        required={false}
                                        defaultValue={form.defaults.photo}
                                        accept={"image/*"}
                                        limit={1}
                                    />

                                </Col>

                            </Row>

                        </Col>

                    </Row>

                    {styles.data.data.length > 0 &&
                        <>
                            <Seperator
                                top={"auto"}
                                bottom={"auto"}
                                heading={"Dance Styles"}
                            />

                            {styles.data.data.map((style, index) => (
                                <Row gutter={8} edge={true} key={style.id}>

                                    <Col col={{ xs: 12, md: 8 }} >

                                        <FieldSwitch
                                            form={form}
                                            name={`styles.${style.id}`}
                                            defaultValue={get(form.defaults, `styles.${style.id}`)}
                                            label={style.title}
                                        />

                                    </Col>

                                    {style.format === "partner" && form.values?.styles[style.id] &&
                                        <Col col={{ xs: 12, md: 8 }} >

                                            <FieldRadio
                                                form={form}
                                                name={`roles.${style.id}`}
                                                defaultValue={get(form.defaults, `roles.${style.id}`)}
                                                label={style.title + " Role"}
                                                options={[
                                                    { value: "lead", label: "Lead" },
                                                    { value: "follow", label: "Follow" },
                                                ]}
                                            />

                                        </Col>
                                    }

                                    {form.values?.styles[style.id] &&
                                        <Col col={{ xs: 24, md: 8 }} >

                                            <FieldRelationship
                                                form={form}
                                                name={`levels.${style.id}`}
                                                defaultValue={get(form.defaults, `levels.${style.id}`)}
                                                label={style.title + " Level"}
                                                model='Groups'
                                                preload={true}
                                                where={{ styleId: style.id, type: "class" }}
                                            />

                                        </Col>
                                    }

                                </Row>
                            ))}

                        </>
                    }

                    <Line />

                    <ButtonRow>
                        <Button
                            label={"Save"}
                            loading={process.loading}
                        />
                        <Button
                            label={"Reset"}
                            type={"faded"}
                            onClick={form.reset}
                        />
                    </ButtonRow>

                </Form>
            }

        </Panel>

    ), [props, styles.hash]);

}

export default StudentsCreateEditForm;
