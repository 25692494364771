import React from 'react';

import { Box, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import DashboardMenu from './DashboardMenu/DashboardMenu';
import ScheduleCalendar from '../../elements/ScheduleCalendar/ScheduleCalendar';

const Dashboard: React.FC<any> = (props: any) => {

    return (

        <Box
            windowHeight={true}
            gutter={"big"}
        >

            <DashboardMenu />

            <Space height={20} />

            <ScheduleCalendar />

        </Box>

    )

}

export default Dashboard;