import React from 'react';
import styles from './AttendanceBar.module.scss';
import { formatNum, howMany } from '@dex/bubl-helpers';
import AttendanceLegend from '../AttendanceLegend/AttendanceLegend';

const AttendanceBar: React.FC<any> = (props: any) => {

    const { data, indicator } = props;

    const limitAttendees = data.limitAttendees || 100;

    const followsAttending = data.attendingFollows + data.attendedFollows + data.noshowFollows;
    const leadsAttending = data.attendingLeads + data.attendedLeads + data.noshowLeads;
    const totalAttending = data.attendingTotal + data.attendedTotal + data.noshowTotal;

    const followsNoShow = data.noshowFollows || 0;
    const leadsNoShow = data.noshowLeads || 0;
    const totalNoShow = data.noshowTotal || 0;

    const followsRetake = data.retakeFollows || 0;
    const leadsRetake = data.retakeLeads || 0;
    const totalRetake = data.retakeTotal || 0;

    const followsAttendingPercentage = ((followsAttending) / limitAttendees) * 100;
    const leadsAttendingPercentage = ((leadsAttending) / limitAttendees) * 100;
    const totalAttendingPercentage = ((totalAttending) / limitAttendees) * 100;

    const followsRetakePercentage = (followsRetake / followsAttending) * 100;
    const leadsRetakePercentage = (leadsRetake / leadsAttending) * 100;
    const totalRetakePercentage = (totalRetake / totalAttending) * 100;

    const followsNoShowPercentage = (followsNoShow / followsAttending) * 100;
    const leadsNoShowPercentage = (leadsNoShow / leadsAttending) * 100;
    const totalNoShowPercentage = (totalNoShow / leadsAttending) * 100;

    return (

        <div>

            <div className={styles.wrap}>
                <div className={styles.bar}>
                    {data?.style?.format === "solo" &&
                        <>
                            <div className={`${styles.segment} ${styles["segment" + totalAttendingPercentage]}  ${styles.bgSolo}`} style={{ width: `${totalAttendingPercentage}%` }}>
                                <div className={styles.noShow} style={{ width: `${totalNoShowPercentage}%` }}></div>
                                <div className={styles.retakeFollow} style={{ width: `${totalRetakePercentage}%` }}></div>
                                <div className={`${styles.count} ${styles.countFollow}`}>{formatNum(totalAttending, 0)} {howMany(totalAttending, "Attendee", "Attendees")}</div>
                            </div>
                        </>
                    }
                    {data?.style?.format === "partner" &&
                        <>
                            <div className={`${styles.segment} ${styles["segment" + followsAttendingPercentage]}  ${styles.bgFollow}`} style={{ width: `${followsAttendingPercentage}%` }}>
                                <div className={styles.noShow} style={{ width: `${followsNoShowPercentage}%` }}></div>
                                <div className={styles.retakeFollow} style={{ width: `${followsRetakePercentage}%` }}></div>
                                <div className={`${styles.count} ${styles.countFollow}`}>{formatNum(followsAttending, 0)} {howMany(followsAttending, "Follow", "Follows")}</div>
                            </div>
                            <div className={`${styles.segment} ${styles["segment" + leadsAttendingPercentage]} ${styles.bgLead}`} style={{ width: `${leadsAttendingPercentage}%` }}>
                                <div className={styles.noShow} style={{ width: `${leadsNoShowPercentage}%` }}></div>
                                <div className={styles.retakeLead} style={{ width: `${leadsRetakePercentage}%` }}></div>
                                <div className={`${styles.count} ${styles.countLead}`}>{formatNum(leadsAttending, 0)} {howMany(leadsAttending, "Lead", "Leads")}</div>
                            </div>
                        </>
                    }
                </div>

                {indicator &&
                    <AttendanceLegend format={data.style.format} />
                }

            </div>

        </div>
    );

};

export default AttendanceBar;