import React, { useMemo, useState } from 'react';
import renderHTML from 'react-render-html';
import { Col, Row, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import MediaImage from '@dex/bubl-dash/src/lib/components/MediaImage/MediaImage';
import { IoIosArrowForward } from "react-icons/io";


import styles from "./RenderContentBlocks.module.scss";

const RenderBlocks: React.FC<any> = (props: any) => {

    let { blocks, text } = props;

    if (!blocks && text) {

        blocks = [{ type: "text", content: text }];

    }

    return useMemo(() => {

        return (
            <div style={{ maxWidth: props.width || 640 }}>
                {blocks.map((block, index) => (
                    <React.Fragment key={index}>

                        {index > 0 &&
                            <Space />
                        }

                        {block.type === "text" &&
                            <RenderContentText block={block} />
                        }

                        {block.type === "space" &&
                            <RenderContentSpace block={block} />
                        }

                        {block.type === "image" &&
                            <RenderContentImage block={block} />
                        }

                        {block.type === "video" &&
                            <RenderContentVideo block={block} />
                        }

                        {block.type === "heading" &&
                            <RenderContentHeading block={block} />
                        }

                        {block.type === "accordion" &&
                            <RenderContentAccordion block={block} />
                        }

                        {block.type === "section" &&
                            <RenderContentSection block={block} />
                        }

                        {block.type === "menuItem" &&
                            <RenderContentMenuItem block={block} />
                        }

                    </React.Fragment>
                ))}
            </div>
        )

    }, [blocks]);

}

const RenderContentText: React.FC<any> = (props: any) => {

    const { block } = props;

    return useMemo(() => {

        return (
            <div className='rich-text-view'>
                {renderHTML(block.text)}
            </div>
        )

    }, [block]);

}

const RenderContentSpace: React.FC<any> = (props: any) => {

    const { block } = props;

    return useMemo(() => {
        return (
            <Space height={block.height} />
        )

    }, [block]);

}


const RenderContentImage: React.FC<any> = (props: any) => {

    const { block } = props;

    return useMemo(() => {

        return (

            <div className='responsive'>
                <MediaImage id={block.image.fileId} filters={{ width: 1280 }} link={true} />
                <p className={styles.imageText}>{block.caption}</p>
            </div>
        )

    }, [block]);

}

const RenderContentVideo: React.FC<any> = (props: any) => {

    const { block } = props;

    return useMemo(() => {

        return (
            <div className='responsive'>
                {block.video.source === "youtube" &&
                    <iframe title={block.video.id} className="ytplayer" width="640" height="360" src={"https://www.youtube.com/embed/" + block.video.id + "?autoplay=0"}></iframe>
                }
                {block.video.source === "facebook" &&
                    <iframe title={block.video.id} className="fbplayer" width="640" height="360" src={"https://www.facebook.com/video/embed?video_id=" + block.video.id}></iframe>
                }
            </div>
        )

    }, [block]);

}

const RenderContentHeading: React.FC<any> = (props: any) => {
    const { block } = props;

    return useMemo(() => {

        return (
            <div className="responsive">

                <h1 className={styles.heading}>{block.heading}</h1>

                <p className={styles.subHeading}>{block.subHeading}</p>

            </div>
        )
    }, [block])
}

const RenderContentAccordion: React.FC<any> = (props: any) => {

    const { block } = props;

    const [expand, setExpand] = useState(null);

    const toggleAccordion = (index) => {
        const newIndex = expand === index ? null : index;
        setExpand(newIndex);
    };

    const renderInnerBlocks = (innerBlocks) => {
        return innerBlocks.map((innerBlock, index) => {
            switch (innerBlock.type) {
                case 'text':
                    return <RenderContentText key={index} block={innerBlock} />;
                case 'image':
                    return <RenderContentImage key={index} block={innerBlock} />;
                case 'video':
                    return <RenderContentVideo key={index} block={innerBlock} />;
                case 'space':
                    return <RenderContentSpace key={index} block={innerBlock} />;
                default:
                    return null;
            }
        });
    };

    return useMemo(() => {
        return (
            <div className={styles.accordion}>
                <div className={styles.accordionItem}>
                    <button
                        id={`accordion-button-${block.id}`}
                        aria-expanded={expand === block.id}
                        onClick={() => toggleAccordion(block.id)}
                        className={styles.accordionToggle}
                    >
                        <h1 className={styles.heading}>{block.heading}</h1>
                        {/* <p className={styles.subHeading}>{block.subHeading}</p> */}

                        <span className={styles.icon} aria-hidden="true"></span>
                    </button>
                    {expand === block.id && (
                        <div className={styles.accordionContent}>
                            {renderInnerBlocks(block.innerBlocks)}
                        </div>
                    )}
                </div>
            </div>
        );
    }, [block, expand]);
}

const RenderContentSection: React.FC<any> = (props: any) => {

    const { block } = props;

    const renderInnerBlocks = (innerBlocks) => {
        return innerBlocks.map((innerBlock, index) => {
            switch (innerBlock.type) {
                case 'text':
                    return <RenderContentText key={index} block={innerBlock} />;
                case 'image':
                    return <RenderContentImage key={index} block={innerBlock} />;
                case 'video':
                    return <RenderContentVideo key={index} block={innerBlock} />;
                case 'space':
                    return <RenderContentSpace key={index} block={innerBlock} />;
                default:
                    return null;
            }
        });
    };

    return useMemo(() => {
        return (

            <div className={styles.section}>

                {(block.heading || block.subHeading) &&
                    <RenderContentHeading block={block} />
                }

                <div className={styles.sectionBody}>
                    {renderInnerBlocks(block.innerBlocks)}
                </div>

            </div>
        );

    }, [block]);
}

const RenderContentMenuItem: React.FC<any> = (props: any) => {
    const { block } = props;

    return useMemo(() => {

        return (

            <div className="responsive">
                <div className={styles.container}>
                    <a
                        className={styles.link}
                        href={block.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className={styles.textContainer}>
                            <h1 className={styles.heading} >{block.heading}</h1>
                            <p className={styles.subHeading}>{block.subHeading}</p>
                        </div>
                        <div className={styles.svgContainer}>
                            <IoIosArrowForward />
                        </div>

                    </a>
                </div>
            </div>


        )
    }, [block])
}

const RenderContentSpotLight: React.FC<any> = (props: any) => {
    const { block } = props;

    return useMemo(() => {

        return (
            <div className="responsive">

                <Row>

                    {block.spotlights.map((item, key) => (

                        <Col col={{ xs: 8 }} key={key} className={styles.spotCol}>

                            <h1>{item.title}</h1>
                            <p>{item.text}</p>
                            <p>{item.caption}</p>

                        </Col>
                    ))}

                </Row>

            </div>
        )

    }, [block])
}

export default RenderBlocks;
