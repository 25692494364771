import ClassesIndex from './ClassesIndex';
import ClassesCreate from './ClassesCreate';
import ClassesSettings from './ClassesSettings';
import ClassesSingle from './ClassesSingle';

export default function (app) {

    const settings = ClassesSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 10,
        items: [
            {
                label: "This Week",
                path: settings.path + "/date/week",
                caps: settings.createCaps
            },
            {
                label: "Today",
                path: settings.path + "/date/today",
                caps: settings.createCaps
            },
            {
                label: "Upcoming",
                path: settings.path + "/date/upcoming",
                caps: settings.createCaps
            },
            {
                label: "Past",
                path: settings.path + "/date/past",
                caps: settings.createCaps
            },
            {
                label: "All Classes",
                path: settings.path,
                caps: settings.indexCaps,
            },
        ],
    });

    ClassesIndex(app, settings);

    ClassesCreate(app, settings);

    ClassesSingle(app, settings);

}