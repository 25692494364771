import React from 'react';
import { titleCase, get, formatNum, howMany } from "@dex/bubl-helpers";
import UserCreditsApi from './UserCreditsApi';
import { Status, WhiteSpace } from '@dex/bubl-dash';


let settings;

export default settings = {
    key: 'credits',
    zone: 'studio',
    path: '/credits',
    title: "Credits",
    endpoint: UserCreditsApi.endpoint,
    modelName: 'UserCredits',
    idKey: 'id',
    primaryKey: 'id',
    indexCaps: ["viewUserCredits"],
    viewCaps: ["viewUserCredits"],
    createCaps: ["createUserCredits"],
    actions: ['trash'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps,
            },
            {
                label: "Active",
                path: settings.path + "/status/active",
                caps: settings.indexCaps,
            },
            {
                label: "Empty",
                path: settings.path + "/status/empty",
                caps: settings.indexCaps,
            },
            {
                label: "Expired",
                path: settings.path + "/status/expired",
                caps: settings.indexCaps,
            },
            {
                label: "Add New",
                path: settings.path + "/create"
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: UserCreditsApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = {
            where: {},
            include: [
                {
                    relation: 'product',
                    preset: "link"
                },
                {
                    relation: 'student',
                    preset: "link"
                },
                {
                    relation: 'user',
                    preset: "link"
                }
            ],
            order: ['created.date DESC'], limit: 10, skip: 0
        };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id', 'title', 'type'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Student",
                key: "studentId",
                type: "relationship",
                model: "Students"
            },
            {
                label: "Pass",
                key: "productId",
                type: "relationship",
                model: "Products"
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "active", label: "Active" },
                    { value: "inactive", label: "Inactive" },
                ]
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Credit ID",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Student",
                key: "student",
                format: "ModelLink"
            },
            {
                label: "Status",
                key: "status",
                format: "status"
            },
            {
                label: "Credits Left",
                key: "leftCredits",
                format: "number",
                suffix: " credits",
                // render: (value, row) => {
                //     let suffix = howMany(value, " credit", " credits");

                //     if (row.status === 'expired') suffix += " expired";
                //     else suffix += " left";

                //     return formatNum(value, 0, "", suffix);
                // }
            },
            {
                label: "Expiry Date",
                key: "expiryDate",
                format: "dateDay",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            // {
            //     label: "Actions",
            //     key: "actions",
            //     alignEnd: true,
            //     format: "actions",
            //     api: UserCreditsApi,
            //     settings: settings,
            // }
        ];

        return columns;

    },
    singleFetch: UserCreditsApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = {
            include: [
                {
                    relation: 'product',
                    preset: "link"
                },
                {
                    relation: 'user',
                    preset: "link"
                },
                {
                    relation: 'student',
                    preset: "link"
                },
                {
                    relation: 'order',
                    preset: "link"
                }
            ]
        };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: any = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = <>{data[settings.primaryKey]} <WhiteSpace /> <Status status={data.status} /></>;
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view",
                caps: settings.viewCaps
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.createCaps
            },
        ]

    },
    singleButtons: (routeName, params, user, fetch) => {

        return [
        ].filter(Boolean);

    },
};