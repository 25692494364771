import React from 'react';
import styles from './AttendanceLegend.module.scss';
import SelfServiceBadge from '../../elements/SelfServiceBadge/SelfServiceBadge';
import { WhiteSpace } from '@dex/bubl-dash';
import PostPaidBadge from '../../elements/PostPaidBadge/PostPaidBadge';
import UnpaidBadge from '../../elements/UnpaidBadge/UnpaidBadge';

const AttendanceLegend: React.FC<any> = (props: any) => {

    const { hideBadge, hideRetake, hideRole, hideStatus, format } = props;

    return (

        <div className={styles.legend}>

            {!hideRole &&
                <>

                    {format === "solo" &&
                        <div className={styles.indicator}>
                            <div className={`${styles.indicatorColor} ${styles.soloColor}`}></div>
                            <span>Attendee</span>
                        </div>
                    }

                    {format === "partner" &&
                        <>
                            <div className={styles.indicator}>
                                <div className={`${styles.indicatorColor} ${styles.followColor}`}></div>
                                <span>Follow</span>
                            </div>

                            <div className={styles.indicator}>
                                <div className={`${styles.indicatorColor} ${styles.leadColor}`}></div>
                                <span>Lead</span>
                            </div>
                        </>
                    }

                    {!hideRetake &&
                        <div className={styles.indicator}>
                            <div className={`${styles.indicatorColor} ${styles.retakeColor}`}></div>
                            <span>Retake</span>
                        </div>
                    }

                </>
            }

            {!hideStatus &&
                <div className={styles.indicator}>
                    <div className={`${styles.indicatorColor} ${styles.noShowColor}`}></div>
                    <span>No Show</span>
                </div>
            }

            {!hideBadge &&
                <>
                    <div className={styles.indicator}>
                        <SelfServiceBadge /><WhiteSpace />
                        <span>Self Service</span>
                    </div>

                    <div className={styles.indicator}>
                        <UnpaidBadge /><WhiteSpace />
                        <span>Unpaid</span>
                    </div>

                    <div className={styles.indicator}>
                        <PostPaidBadge /><WhiteSpace />
                        <span>Post Paid</span>
                    </div>

                </>
            }

        </div>

    );

};

export default AttendanceLegend;