import React, { useMemo } from 'react';
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import UsersStudentProfiles from './Components/UsersStudentProfiles';
import UsersOrderHistory from './Components/UsersOrderHistory';

const UsersSingleViewMain: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>

            <Space />

            <UsersStudentProfiles {...props} />

            <Space />

            <UsersOrderHistory {...props} />

        </>

    ), [data, props]);

}

export default UsersSingleViewMain;
